// Display Property
.display-block {
    display: block;
    
    &-override { display: block !important; }
    @include breakpoint(small only) {
        &-for-small { display: block; }
    }
    @include breakpoint(medium only) {
        &-for-medium { display: block; }
    }
    @include breakpoint(large) {
        &-for-large { display: block; }
    }
}

.display-inline-block {
    display: inline-block;

    &-override { display: inline-block !important; }
    @include breakpoint(small only) {
        &-for-small { display: inline-block; }
    }
    @include breakpoint(medium only) {
        &-for-medium { display: inline-block; }
    }
    @include breakpoint(large) {
        &-for-large { display: inline-block; }
    }
}

.display-inline {
    display: inline;
    &-override { display: inline !important; }
    @include breakpoint(small only) {
        &-for-small { display: inline; }
    }
    @include breakpoint(medium only) {
        &-for-medium { display: inline; }
    }
    @include breakpoint(large) {
        &-for-large { display: inline; }
    }
}

.no-mobile-display {
    display:block;
    @include breakpoint(small only) {
        display: none;
    }
}

.mobile-display {
    display:none;
    @include breakpoint(small only) {
        display: block;
    }
}
