// Position Helpers
.position-relative {
    position: relative;
}

.position-fixed {
    position: fixed;
}

.position-absolute {
    position: absolute;
}

.move-to-top {
    top: 0;
}
.move-to-bottom {
    bottom: 0;
}

.move-to-bottom-sixty {
    bottom: 60px;
}

.move-to-left {
    left: 0;
}
.move-to-right {
    right: 0;
}

.full-absolute {
   /* @include full-absolute;*/
}

.vertical-align-middle {
    top: 50%;
    transform: translateY(-50%);
}

.horizontal-align-middle {
    left: 50%;
    transform: translateX(-50%);
}

.float-left {
    float:left;
}

.float-right {
    float:right;
}


