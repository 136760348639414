


/* site logo */

.site-logo {
  position:fixed;
  top:10px;
  left:10px;
  z-index: 100;

  & img {
    height:100px;
    -webkit-transition: all;
  }
}

.dropdown.menu .is-active > a {
    background: transparent !important;
    color: $black !important;
    opacity: 1.0 !important;
  
}



.dropdown a:hover {
    background: none !important;
    background-color: none !important;
}
.active .dropdown.menu  .is-active:after {
  content:url('') !important; 
  top: 0;
  display: none;
  }

.top-navigation-bar {
  display: none;
  @include breakpoint(large) {
    display: block;
  }
}

/* socials */

#socials {
  /*display: none;
  @include breakpoint(large) {
    display: block;
  }*/
  opacity: 0.6 !important;
  padding:5px 45px 0 0;
  
  @include breakpoint(large) {
    padding:15px 15px 0 0;
  }


  & a {
    color: $dark-gray;
    opacity: 1;
    font-size: rem-calc(18px);
    transition: all 0.2s ease-in-out;  
  }

  & a:hover {
    opacity: 1.0;
    color: $primary-color;
  }

  & a:not(:last-child) { margin-right: 10px; }

}


/* end socials */



/* building blocks */

$overlay-nav-menu-toggle-color: $black;
$overlay-nav-menu-link-height: 6.25rem;
$overlay-nav-menu-link-color: $black;
$overlay-nav-menu-link-border: 1px solid rgba($overlay-nav-menu-link-color, 0.5);
$overlay-nav-menu-bg: $black;

.overlay-nav-menu-toggle {
  display: block;
  @include breakpoint(large) {
    display:none;
  }

  position:fixed;
  font-size: rem-calc(22px);
  z-index: 5000;
  color: $black;
  top: 18px;
  right: 15px;
  transition: all 0.4s ease-in-out;

  &:hover {
    /*transition: all 0.4s ease-in-out;
    color: lighten($overlay-nav-menu-toggle-color, 30%);*/
  }
}

.overlay-nav-menu {

  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  margin:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  list-style: none;
  /*background: $overlay-nav-menu-bg;*/
  background: $primary-color;
  background-color:rgba(255, 255, 255, 0.9) !important;
  background-size: cover;
  text-align: center;
  transform: translateX(-100%) rotateX(40deg);
  transition: all 400ms cubic-bezier(1.000, -0.400, 0.440, 0.985);

  li {
    font-size: 2.5rem;
  }

  a {
    display: block;
    color: $overlay-nav-menu-link-color;
    font-weight: normal;
    height: $overlay-nav-menu-link-height;
    line-height: $overlay-nav-menu-link-height;
    /*border-bottom: $overlay-nav-menu-link-border;*/
    text-decoration: none;

    &:hover {
      background-color: rgba($black, 0.6);
      color: $white;
    }
  }

  &.is-open {
    top: 0;
    opacity: 1;
    transform: perspective(200px) translateX(0) rotateX(0);
  }

  &.is-open ~ .overlay-nav-menu-toggle {
    display: inline-block;
    transition: all 0.4s ease-in-out;
    transform: rotate(135deg);
    color: lighten($black, 100%);
  }
}



/* end building blocks */

.top-bar, .top-bar ul {
  background-color: rgba(0,0,0,0);
}
.site-header {
      position: fixed;
      top:0px;
    left: 0;
    z-index: 12;
    width: 100%;
    /*background: $black;*/
    padding-top:0;
    & .logo {     
        -webkit-transition: all 1s ease;
        height: auto;
        width: 280px;
        max-width: 435px;
        -webkit-transition: all 1s ease;
        margin-top: 4px;
        margin-left: -5px;

      @include breakpoint(small only) {
        margin-top:5px !important;
         width: 260px !important;
      }
    }
}

.logo {     
        -webkit-transition: all 1s ease;
        height: auto;
        width: 100%;
        max-width: 135px;
        -webkit-transition: all 1s ease;
        margin-top: 20px;
        margin-left: 20px;

      @include breakpoint(small only) {
        margin-top:20px !important;
      }
}

.active {
  background-color: $white !important;
  -webkit-box-shadow: 0px 24px 70px 0px rgba(0,0,0,0.15);
-moz-box-shadow: 0px 24px 70px 0px rgba(0,0,0,0.15);
box-shadow: 0px 24px 70px 0px rgba(0,0,0,0.15);
}

.active_small {
  /*width:100% !important;
  margin-top: 5px !important;
  max-width:200px !important;*/
}

.active_menu {
  margin-top: 0px !important;
}



// Navigation breakpoints
.mobile-menu,
.site-title-bar {
  @include hide-for(medium);
}

.desktop-menu,
.site-navigation .top-bar-left {
  @include show-for(medium);
}

// Site title
.site-desktop-title a {
  font-weight: bold;
}

#overlay-nav-menu {

  & .menu .is-active > a {
   background: none !important;
    color: $black !important;
  }

  & .menu .is-active > a:before {

  }

  & .menu .is-active > a:after {

  }
}
// Mobile menu
.mobile-menu {
  display: none; // prevents repaint caused by JS hiding menu onload
}

.off-canvas {
  > ul.menu {
    height: 100vh;
    padding: 1rem;

    a {
      color: $white;
      font-weight: 600;
      font-size: rem-calc(15);
    }
  }

  .menu .active > a { background-color: #ccc; }

}

.title-bar-title {
  a {
    font-size: 1rem;
    color: #B9B9B9;
  }
}

.overlay-nav-menu, .mobile-menu,
.mobile-off-canvas-menu {

  .menu .is-active > a {
    background-color: none;
  }
}

// Tablet and desktop menu

.top-bar {
  background-color: $white !important;
    box-shadow: 0px 14px 70px 0px rgba(0, 0, 0, 0.45);

  .top-bar-title a {
    font-size: 1rem;
    color: #B9B9B9;
    padding-left: 1rem;
    line-height: 1.8;
  }

  .menu {
    width: 100%;
    margin: auto;
    justify-content: center;
    margin-top:0px;
  }

  .menu a {
    color: $dark-gray ;
    opacity: 1;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 700;
    font-size: 1.0rem;
    line-height: 1;
     transition: all 0.2s ease-in-out;

    &:hover:not(.button) {
      background-color: $black;
    }
  }

  .menu .active > a { background-color: #ccc; }

  .menu>li:not(.menu-text)>a { 
    padding: 1.5rem 1rem 2rem 1rem;
    margin: 0 .5rem;
  }
  /*.menu li:not(:last-child) { border-right: 1px solid #4e4e4e; }*/
  .menu li:not(:last-child) { border-right: 0px solid #4e4e4e; }

  .dropdown.menu .submenu { border: 0; background: $black !important;}
  .dropdown.menu .has-submenu.is-down-arrow a { padding-right: 0.5rem; }
  .dropdown.menu .has-submenu.is-down-arrow > a::after { border: 0; }
  .dropdown.menu:first-child > li.is-dropdown-submenu-parent > a::after { display: none;    margin-top: -20px; }
}

.site-navigation {
  @include breakpoint(small only) {
    padding: 0; // prevents container visibility on small screens
  }
}

// WP post navigation
.post-navigation {
  @include clearfix;
}

.nav-previous {
  float:left;
}

.nav-next {
  float:right;
}


/* hover */



/*Sweep To Right*/
.top-bar .menu li a, .hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}


.top-bar .menu li a:before, .hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20px;
  background: none;
  border-bottom: 0px solid $primary-color;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.top-bar .menu li a:hover, .hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
  color: $primary-color !important;
  opacity: 1 !important;
  text-decoration: none !important;
  background: none !important;
}
.top-bar .menu li a:hover:before, .hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}



/* end hover */


