.font-weight-bold {
	font-weight:bold;
}

// Typography
// - - - - - - - - - - - - - - - - - - - - - - - - -


// Text Selection
::selection {
    background-color: lighten( $primary-color, 5% );
    color: $white;
}

@font-face {
    font-family: 'theartist';
    src: url('../fonts/made_theartist_sans_personal_use-webfont.woff2') format('woff2'),
         url('../fonts/made_theartist_sans_personal_use-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.secondary-font {
    font-family: $secondary-font;
}

// Text Color
.color-primary {
    color: $primary-color;
}

.color-secondary {
    color: $secondary-color;
}

.color-black {
    color: $black;
    &:hover {
    	color:$alternative-color;
    }
}

.color-white {
    color: $white;
}

.color-body {
    color: $body-font-color;
}

.color-grey {
    color: #666
}


// Weight Helpers
.weight-normal {
    font-weight: $global-weight-normal;
}

.weight-bold {
    font-weight: $global-weight-bold;
}

.small_text {
    font-size: $small-font-size
}

.small-text {
    font-size: $small-font-size
}

.blue {
    color: $primary-color;
}

.color-blue {
    color: $primary-color;
}


.custom_gradient .blue {
    color: $white;
}

.uppercase {
    text-transform: uppercase
}



.font-size-twenty {
    font-size:22px;
    @include breakpoint(small only) {
        font-size: 20px;
    }
}

.font-size-sixteen {
    font-size:16px;
    @include breakpoint(small only) {
        font-size: 15px;
    }
}



