.tickets {
	font-size:80%;
	font-weight:bold;
	color: $white;
	background:$primary-color;
	border:1px solid $primary-color;
	text-decoration:none;
	padding:10px;
	transition: all 0.4s ease-in-out;
}

.tickets:hover {
	background:$black;
	border:1px solid $primary-color;
}

#tourdates, #tourdates tr, #tourdates td , #tourdates tbody {
	background:none;
	background-color:transparent;
}

#tourdates tbody {
	border:0;
}

.memo {
	display: block;
	@include breakpoint(small only) {
        display: none;
    }
}