
.home_line_one {

}

.home_line_two {
	@include breakpoint(medium) {
		margin-left:20rem !important;
	}
}


#flowers-bottom-right {
	text-align:right;z-index:1;position:absolute;bottom:0;right:0;
	@include breakpoint(small only) {
			display:none;
	}

}

#mc_embed_signup {
    margin: auto !important;
}

#mc_embed_signup .clear {
    text-align: center !important;
}

#mc_embed_signup h2 {
    text-align: center !important;
}

#mc_embed_signup .button {
    border-radius: 0 !important;
}

#mc_embed_signup #mce-success-response {
    color: #fff !important;
}
#mc_embed_signup div.response {
    margin: 0 !important;
    padding: 0 0 10px 0 !important;
        width: 100% !important;
    text-align: center !important;
}

#mc_embed_signup .mc-field-group {
    padding-bottom: 0 !important;
}

#home_tour {
	position:fixed;
	z-index:10;
	bottom:0px;
	left:0;
	width:100%;
	
	color:$white;
	

	& .tour {
		background:$black;
		background: rgba(0,0,0,0.4);
		padding: 30px 0;
	}

	& .responsive {
		width:70%;
		@include breakpoint(medium) {
			width:90%;
		}
		margin: auto;
	}

	& h3 {
		margin-bottom: 0;
		font-weight: 800;
	}

	& h4 {
		color: $primary-color !important;
	}

	& span {
		color: $white !important;
	}

	& a:hover {
		opacity: 0.6;
	}

}

.orbit-container { height: 100vh !important; }  /* Set to height of slides */

.orbit-container .orbit-slides-container > * {
  position: relative !important;
  margin-left: 0 !important;
  float: left !important;
  height: 100% !important;
}

.orbit-slide {
	@include breakpoint(large) {

	}
	background-size:cover !important;
}
.orbit-slide, .orbit-figure {
	height: 100vh;
}


    .tag_line {
        z-index: 2;
        text-align: left;
        top:53%;
        left:3%;
        position: absolute;
        

        & h1 {
         font-size:3.2rem !important;
        /*color: $primary-color;*/
        font-weight: 600;
        line-height: 0.8;
        }
    }
.title{
  font-weight: 800;
  color: transparent;
  font-size:120px;
  background: url("http://104.152.168.27/~fairytale/wp-content/themes/Porter/dist/assets/images/textmask.jpg") repeat;
  background-position: 40% 50%;
  -webkit-background-clip: text;
  position:relative;


}


.featured-placement {
  font-weight:700;font-size:36px;
  
  @include breakpoint(small only) {
      font-size:16px;

  }

}

.orbit-bullets {
    position: relative;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    text-align: left;
    Z-INDEX: 1;
    bottom: -30px;
    LEFT: 2%;
    WIDTH: 90%;
    
}

.orbit-bullets button.is-active {
    background-color: #fff;
    -webkit-box-shadow:0 0 3px $black; 
-moz-box-shadow: 0 0 3px $black; 
box-shadow:0 0 3px $black;
}

.orbit-caption {
    width: 90%;
    left:3%;
    padding: 1rem;
    background-color: transparent;
    height: 35%;

    @include breakpoint(small only) {
    	left:8%;
    	width:80%;
      height: 60%;
    }

    & h1 {
    	line-height:1;
    	color: $white;
        font-size:2.9rem;
        font-weight:600;
            text-shadow: 0 1px 2px rgba(0,0,0,.6);


    }

    @include breakpoint(small only) {
        & h1 {
          font-size:1.9rem;
        }
    }

    & .button {
    	padding: 0.85em 1.9em;
    	border: 3px solid $alternative-color;
	}

	& .button:hover {
    	border:3px solid $white;
	}
}

.orbit-bullets button {
    width: 0.8rem;
    height: 0.8rem;
    margin: 0.2rem;
    border-radius: 50%;
    background-color: #999;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
	.orbit-slide, html {
	 	background-size: 1280px 853px !important;
    -webkit-background-size: 1280px 853px !important;
      -moz-background-size: 1280px 853px !important;
      -o-background-size: 1280px 853px !important;
    background-attachment: scroll !important;
    background-position: top center !important;
	 }
 }
 
@media all and (min-width: 768px) and (max-width: 1024px) {
	.orbit-slide, html {
	 	background-size: 1200px 800px !important;
    -webkit-background-size: 1200px 800px !important;
      -moz-background-size: 1200px 800px !important;
      -o-background-size: 1200px 800px !important;
    background-attachment: scroll !important;
    background-position: top center !important;
	 }
 }
 
@media all and (min-width: 480px) and (max-width: 768px) {
	.orbit-slide, html {
	 	background-size: 1200px 800px !important;
    -webkit-background-size: 1200px 800px !important;
      -moz-background-size: 1200px 800px !important;
      -o-background-size: 1200px 800px !important;
    background-attachment: scroll !important;
    background-position: top center !important;
	 }
 }

@include breakpoint(small only) {
	 .orbit-slide, html {
	 	background-size: 1200px 800px !important;
    -webkit-background-size: 1200px 800px !important;
      -moz-background-size: 1200px 800px !important;
      -o-background-size: 1200px 800px !important;
    background-attachment: scroll !important;
    background-position: top center !important;
	 }
}



