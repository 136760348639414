// Page header

#background {
      z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#main_content {
  background:#000;
  background:rgba(0,0,0,0.5);
  color:#fff;
  z-index:-2;
  left:0;
  top:0;
  width:100%;
  height:100vh;
  position:fixed;
  @include breakpoint(large) {
      height: 100%;
      position: fixed;
      overflow: scroll;
  }

  &-transparent {
    background:rgba(0,0,0,0.3);
    color:#fff;
    z-index:-2;
    left:0;
    top:0;
    width:100%;
    height:100vh;
     position: fixed;
  }
  

  & h1 {
    font-weight: bold;
  }
}

.home_feature {
  & a {
    color:#fff !important;
  }

  & a:hover {
    color:#fff !important;
    opacity: 0.7;
  }
}

.main-content-opacity, .main_content_opacity {
  background:rgba(0,0,0,0.7) !important;
}

.body-content {
  text-align: center;
  margin-top: 8rem;
}

.loading_image {
  margin:auto;

}

.front-hero {
  .marketing {
    @include xy-grid-container(55rem);
    @include xy-grid;
  }

.test {
  background:none;
}

  @include breakpoint(small) {
    /*background: url('../images/demo/hero-bg-foundation-6-small.svg') bottom center;*/
    background-size: cover;
    background-position: bottom;
    padding: rem-calc(65%) 0;
    margin: 0;
    height: auto;
    position: relative;
    text-align: left;
  }

  @include breakpoint(medium) {
    /*background: url('../images/demo/hero-bg-foundation-6-large.svg') bottom center;*/
    background-size: cover;
    background-position: center;
    height: rem-calc(685);
    margin: 0 0 0;
  }

  .watch {
    @include breakpoint(small) {
      @include xy-cell(7);
    }

    @include breakpoint(medium) {
      @include xy-cell(12);
    }

    a {
      color: #B4C9D1;
      cursor: pointer;
      font-weight: 400;
      margin-right: rem-calc(20);
    }

    a:hover {
      color: #fff;
    }

    #stargazers {
      :before {
        content: "\f09b";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        color: #B4C9D1;
        margin-right: rem-calc(8);
      }
    }

    #twitter {
      :before {
        content: "\f099";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        color: #B4C9D1;
        margin-right: rem-calc(8);
      }
    }

  }

  .tagline {
    @include breakpoint(small) {
      @include xy-cell(8);
    }

    @include breakpoint(medium) {
      @include xy-cell(5);
      padding-top: 6rem;
    }
  }

  h1 {
    color: #fff;
    font-weight: 500;

    @include breakpoint(small) {
      font-size: 2.2rem;
    }

    @include breakpoint(medium) {
      font-size: 2.875rem;
    }
  }

  h4 {
    color: #fefefe;
    font-weight: 300;
    font-size: 1.3125rem;
  }

  .download {
    margin-top: rem-calc(20);
  }
}


//MLS Search
.mls-search {
  background: $secondary-color;

  & input {
    background:none;
    background-color: transparent;
    border: 1px solid $white;
  }
}


// Intro
.intro {
  @include xy-grid-container;
  @include xy-grid;

  .fp-intro {
    @include breakpoint(small) {
      @include xy-cell(12);
    }

    @include breakpoint(medium) {
      @include xy-cell(10);
      @include xy-cell-offset(1);

      h2 {
        font-weight: 300;
        margin-bottom: 1.5rem;
      }

      h4 {
        font-size: 1.125rem;
        line-height: 1.6;
        color: #777;
        margin-bottom: 2rem;
      }
    }
  }
}

//Contact Bar 

.contact-bar {

  & a {
    color:#000 !important;
  }

  & a:hover {
    text-decoration: underline;
  }
}

// Section divider
.section-divider {
  @include xy-grid-container;
  @include xy-grid;

  hr {
    @include xy-cell(12);
    @extend .dotted;
    box-sizing: border-box;
  }
}

// Benefits
.benefits {
  @include xy-grid-container;
  @include xy-grid;
  text-align: center;

  header {
    @include xy-cell(12);

    h2 {
      font-weight: 300;
    }

    h4 {
      font-size: 1.125rem;
      line-height: 1.6;
      color: #777;
    }
  }

  .semantic,
  .responsive,
  .customizable,
  .professional {
    @include breakpoint(small) {
      @include xy-cell(12);
    }

    @include breakpoint(medium) {
      @include xy-cell(3);
    }

    img {
      padding: 1.25rem;
      margin: 1rem auto 0 auto;
    }

    h3 {
      color: #0a0a0a;
      font-weight: 300;
      font-size: 1.75rem;
    }

    p {
      font-size: 0.9375rem;
    }
  }

  .why-foundation {
    @include xy-cell(12);
    margin-top: 4rem;
  }
}


.contact-bottom-right {
  z-index:1;
  position:absolute;
  bottom:0;
  right:0;
  padding: 13px 35px;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  & a {
    color:$dark-gray !important;
  }

  [type='tel'] { color:#cc000 !important; text-decoration: none; }

}


