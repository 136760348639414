.footer-container {
  margin: 0 auto;
  /*margin-top: rem-calc(60);*/
  marign-top:0;
  background: $white;
  color: $black;
}

.footer {
  @include xy-grid-container;
  @include xy-grid;
  padding: rem-calc(30) 0;
  background: $white;
  color: $black;
  
  & a {
    color: $white;
  }

  

  & h3 {
    font-weight:bold;
    font-size: rem-calc(19);
  }

  & .menu .is-active > a {
    background: $black;
    color: $alternative-color !important;
  }

  & .menu  a {
    background: $black;
    color: $white;
  }

  & a:hover {
    color: $primary-color !important;
  }




  article {
    @include xy-cell(auto);
    margin: 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
