@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: "brandon-grotesque", "Helvetica Neue", Georgia, Helvetica, Arial, "hypatia-sans-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px; } }

.row.small-unstack > .column, .row.small-unstack > .columns {
  flex: 0 0 100%;
  flex: 1 1 0px; }

@media print, screen and (min-width: 40em) {
  .small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px; } }

.row.small-unstack > .column, .row.small-unstack > .columns {
  flex: 0 0 100%;
  flex: 1 1 0px; }

@media print, screen and (min-width: 64em) {
  .small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.grid-container {
  max-width: 75rem;
  margin: 0 auto; }

.grid-container-padded {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container-padded {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; } }
  @media screen and (min-width: 75em) {
    .grid-container-padded {
      padding-left: 0;
      padding-right: 0; } }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > small-shrink, .grid-x > small-full, .grid-x > small-1, .grid-x > small-2, .grid-x > small-3, .grid-x > small-4, .grid-x > small-5, .grid-x > small-6, .grid-x > small-7, .grid-x > small-8, .grid-x > small-9, .grid-x > small-10, .grid-x > small-11, .grid-x > small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > medium-shrink, .grid-x > medium-full, .grid-x > medium-1, .grid-x > medium-2, .grid-x > medium-3, .grid-x > medium-4, .grid-x > medium-5, .grid-x > medium-6, .grid-x > medium-7, .grid-x > medium-8, .grid-x > medium-9, .grid-x > medium-10, .grid-x > medium-11, .grid-x > medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > large-shrink, .grid-x > large-full, .grid-x > large-1, .grid-x > large-2, .grid-x > large-3, .grid-x > large-4, .grid-x > large-5, .grid-x > large-6, .grid-x > large-7, .grid-x > large-8, .grid-x > large-9, .grid-x > large-10, .grid-x > large-11, .grid-x > large-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.875rem);
      margin-left: 0.9375rem;
      margin-right: 0.9375rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.25rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.25rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.25rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.25rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.25rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.25rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.25rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.25rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.25rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.875rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.875rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.25rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.25rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.25rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.875rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  .small-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .small-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .small-margin-collapse > .medium-3 {
    width: 25%; }
  .small-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .small-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .small-margin-collapse > .medium-6 {
    width: 50%; }
  .small-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .small-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .small-margin-collapse > .medium-9 {
    width: 75%; }
  .small-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .small-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .small-margin-collapse > .medium-12 {
    width: 100%; }
  .small-margin-collapse > .large-1 {
    width: 8.33333%; }
  .small-margin-collapse > .large-2 {
    width: 16.66667%; }
  .small-margin-collapse > .large-3 {
    width: 25%; }
  .small-margin-collapse > .large-4 {
    width: 33.33333%; }
  .small-margin-collapse > .large-5 {
    width: 41.66667%; }
  .small-margin-collapse > .large-6 {
    width: 50%; }
  .small-margin-collapse > .large-7 {
    width: 58.33333%; }
  .small-margin-collapse > .large-8 {
    width: 66.66667%; }
  .small-margin-collapse > .large-9 {
    width: 75%; }
  .small-margin-collapse > .large-10 {
    width: 83.33333%; }
  .small-margin-collapse > .large-11 {
    width: 91.66667%; }
  .small-margin-collapse > .large-12 {
    width: 100%; }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; }
    .medium-margin-collapse > .small-1 {
      width: 8.33333%; }
    .medium-margin-collapse > .small-2 {
      width: 16.66667%; }
    .medium-margin-collapse > .small-3 {
      width: 25%; }
    .medium-margin-collapse > .small-4 {
      width: 33.33333%; }
    .medium-margin-collapse > .small-5 {
      width: 41.66667%; }
    .medium-margin-collapse > .small-6 {
      width: 50%; }
    .medium-margin-collapse > .small-7 {
      width: 58.33333%; }
    .medium-margin-collapse > .small-8 {
      width: 66.66667%; }
    .medium-margin-collapse > .small-9 {
      width: 75%; }
    .medium-margin-collapse > .small-10 {
      width: 83.33333%; }
    .medium-margin-collapse > .small-11 {
      width: 91.66667%; }
    .medium-margin-collapse > .small-12 {
      width: 100%; }
    .medium-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .medium-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .medium-margin-collapse > .medium-3 {
      width: 25%; }
    .medium-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .medium-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .medium-margin-collapse > .medium-6 {
      width: 50%; }
    .medium-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .medium-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .medium-margin-collapse > .medium-9 {
      width: 75%; }
    .medium-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .medium-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .medium-margin-collapse > .medium-12 {
      width: 100%; }
    .medium-margin-collapse > .large-1 {
      width: 8.33333%; }
    .medium-margin-collapse > .large-2 {
      width: 16.66667%; }
    .medium-margin-collapse > .large-3 {
      width: 25%; }
    .medium-margin-collapse > .large-4 {
      width: 33.33333%; }
    .medium-margin-collapse > .large-5 {
      width: 41.66667%; }
    .medium-margin-collapse > .large-6 {
      width: 50%; }
    .medium-margin-collapse > .large-7 {
      width: 58.33333%; }
    .medium-margin-collapse > .large-8 {
      width: 66.66667%; }
    .medium-margin-collapse > .large-9 {
      width: 75%; }
    .medium-margin-collapse > .large-10 {
      width: 83.33333%; }
    .medium-margin-collapse > .large-11 {
      width: 91.66667%; }
    .medium-margin-collapse > .large-12 {
      width: 100%; }
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; }
    .large-margin-collapse > .small-1 {
      width: 8.33333%; }
    .large-margin-collapse > .small-2 {
      width: 16.66667%; }
    .large-margin-collapse > .small-3 {
      width: 25%; }
    .large-margin-collapse > .small-4 {
      width: 33.33333%; }
    .large-margin-collapse > .small-5 {
      width: 41.66667%; }
    .large-margin-collapse > .small-6 {
      width: 50%; }
    .large-margin-collapse > .small-7 {
      width: 58.33333%; }
    .large-margin-collapse > .small-8 {
      width: 66.66667%; }
    .large-margin-collapse > .small-9 {
      width: 75%; }
    .large-margin-collapse > .small-10 {
      width: 83.33333%; }
    .large-margin-collapse > .small-11 {
      width: 91.66667%; }
    .large-margin-collapse > .small-12 {
      width: 100%; }
    .large-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .large-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .large-margin-collapse > .medium-3 {
      width: 25%; }
    .large-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .large-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .large-margin-collapse > .medium-6 {
      width: 50%; }
    .large-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .large-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .large-margin-collapse > .medium-9 {
      width: 75%; }
    .large-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .large-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .large-margin-collapse > .medium-12 {
      width: 100%; }
    .large-margin-collapse > .large-1 {
      width: 8.33333%; }
    .large-margin-collapse > .large-2 {
      width: 16.66667%; }
    .large-margin-collapse > .large-3 {
      width: 25%; }
    .large-margin-collapse > .large-4 {
      width: 33.33333%; }
    .large-margin-collapse > .large-5 {
      width: 41.66667%; }
    .large-margin-collapse > .large-6 {
      width: 50%; }
    .large-margin-collapse > .large-7 {
      width: 58.33333%; }
    .large-margin-collapse > .large-8 {
      width: 66.66667%; }
    .large-margin-collapse > .large-9 {
      width: 75%; }
    .large-margin-collapse > .large-10 {
      width: 83.33333%; }
    .large-margin-collapse > .large-11 {
      width: 91.66667%; }
    .large-margin-collapse > .large-12 {
      width: 100%; }
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.625rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.625rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.625rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.625rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.625rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.625rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.625rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.625rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.625rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.625rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.625rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.625rem); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > small-shrink, .grid-y > small-full, .grid-y > small-1, .grid-y > small-2, .grid-y > small-3, .grid-y > small-4, .grid-y > small-5, .grid-y > small-6, .grid-y > small-7, .grid-y > small-8, .grid-y > small-9, .grid-y > small-10, .grid-y > small-11, .grid-y > small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > medium-shrink, .grid-y > medium-full, .grid-y > medium-1, .grid-y > medium-2, .grid-y > medium-3, .grid-y > medium-4, .grid-y > medium-5, .grid-y > medium-6, .grid-y > medium-7, .grid-y > medium-8, .grid-y > medium-9, .grid-y > medium-10, .grid-y > medium-11, .grid-y > medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > large-shrink, .grid-y > large-full, .grid-y > large-1, .grid-y > large-2, .grid-y > large-3, .grid-y > large-4, .grid-y > large-5, .grid-y > large-6, .grid-y > large-7, .grid-y > large-8, .grid-y > large-9, .grid-y > large-10, .grid-y > large-11, .grid-y > large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }

.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame {
  width: 100vw;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    width: 100vw;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    width: 100vw;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    height: 100vh;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    height: 100vh;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "brandon-grotesque", "Helvetica Neue", Georgia, Helvetica, Arial, "hypatia-sans-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 1.625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 2.625rem; }
  h2 {
    font-size: 2.125rem; }
  h3 {
    font-size: 1.6875rem; }
  h4 {
    font-size: 1.125rem; }
  h5 {
    font-size: 1rem; }
  h6 {
    font-size: 0.875rem; } }

a {
  line-height: inherit;
  color: #000;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: black; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #777; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #777; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e7e7e7;
  font-family: "brandon-grotesque", Georgia, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e7e7e7;
  font-family: "brandon-grotesque", Georgia, "Liberation Mono", Courier, monospace;
  color: #000; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #777; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #777;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #000;
  color: #fff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: black;
    color: #fff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #000;
    color: #fff; }
    .button.primary:hover, .button.primary:focus {
      background-color: black;
      color: #fff; }
  .button.secondary {
    background-color: #000;
    color: #fff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: black;
      color: #fff; }
  .button.success {
    background-color: #3adb76;
    color: #000; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #000; }
  .button.warning {
    background-color: #ffae00;
    color: #000; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #000; }
  .button.alert {
    background-color: #cc4b37;
    color: #000; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #000; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #000;
      color: #fff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #000;
        color: #fff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #000;
        color: #fff; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #000; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #000; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #000; }
  .button.hollow {
    border: 1px solid #000;
    color: #000; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: black;
      color: black; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #000;
        color: #000; }
    .button.hollow.primary {
      border: 1px solid #000;
      color: #000; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: black;
        color: black; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #000;
          color: #000; }
    .button.hollow.secondary {
      border: 1px solid #000;
      color: #000; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: black;
        color: black; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #000;
          color: #000; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #000;
    color: #000; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: black;
      color: black; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #000;
        color: #000; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #000;
      color: #000; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: black;
        color: black; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #000;
          color: #000; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #000;
      color: #000; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: black;
        color: black; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #000;
          color: #000; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #000; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #000; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #000; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #000;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #777;
    background-color: #fff;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e7e7e7;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #000; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e7e7e7;
  color: #000;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28119, 119, 119%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #777;
    background-color: #fff;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e7e7e7;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #000;
  color: #fff; }
  .badge.primary {
    background: #000;
    color: #fff; }
  .badge.secondary {
    background: #000;
    color: #fff; }
  .badge.success {
    background: #3adb76;
    color: #000; }
  .badge.warning {
    background: #ffae00;
    color: #000; }
  .badge.alert {
    background: #cc4b37;
    color: #000; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #000;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #000; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #000;
    color: #fff; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: black;
      color: #fff; }
  .button-group.secondary .button {
    background-color: #000;
    color: #fff; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: black;
      color: #fff; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #000; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #000; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #000; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #000; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #000; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #000; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0;
  background-color: white;
  color: #000; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d9d9d9;
    color: #000; }
  .callout.secondary {
    background-color: #d9d9d9;
    color: #000; }
  .callout.success {
    background-color: #e1faea;
    color: #000; }
  .callout.warning {
    background-color: #fff3d9;
    color: #000; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #000; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e7e7e7;
  border-radius: 0;
  background: #fff;
  box-shadow: none;
  overflow: hidden;
  color: #000; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e7e7e7; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #777;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #000; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    white-space: nowrap;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #000;
    color: #fff; }
  .menu .active > a {
    background: #000;
    color: #fff; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #000;
    box-shadow: 0 7px 0 #000, 0 14px 0 #000;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #777;
    box-shadow: 0 7px 0 #777, 0 14px 0 #777; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fff; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fff;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #000; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #000 transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #000 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #000 transparent transparent;
  right: 5px;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: #36454f;
  color: #e7e7e7; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #000 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #000; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #000 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #000 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fff; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #000;
  color: #fff; }
  .label.primary {
    background: #000;
    color: #fff; }
  .label.secondary {
    background: #000;
    color: #fff; }
  .label.success {
    background: #3adb76;
    color: #000; }
  .label.warning {
    background: #ffae00;
    color: #000; }
  .label.alert {
    background: #cc4b37;
    color: #000; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fff; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(0, 0, 0, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #777; }
    .orbit-bullets button.is-active {
      background-color: #777; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #000; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e7e7e7; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #000;
    color: #fff;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #000; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fff;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fff;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #000; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f2f2f2;
    background-color: #fff; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f9f9f9;
    color: #000; }
  table tfoot {
    background: #f2f2f2;
    color: #000; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f2f2f2; }
  table.unstriped tbody {
    background-color: #fff; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f2f2f2;
      background-color: #fff; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f4f4f4; }

table.hover tfoot tr:hover {
  background-color: #ededed; }

table.hover tbody tr:hover {
  background-color: #fafafa; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fff;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #2C3840;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #2C3840; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; }

.color-white {
  color: #fff; }

.color-gray {
  color: #777; }

.color-black {
  color: #000; }

.color-alternative {
  color: #000; }

#wpadminbar {
  position: fixed !important; }

body.admin-bar.f-topbar-fixed .sticky.fixed {
  margin-top: 2rem; }

body.admin-bar.offcanvas .off-canvas.is-open {
  top: 2.875rem; }
  @media screen and (min-width: 48.9375em) {
    body.admin-bar.offcanvas .off-canvas.is-open {
      top: 2rem; } }

.wp-caption > figcaption {
  max-width: 100%;
  font-size: 0.8rem;
  color: #999;
  padding: 0.25rem 0; }

p.wp-caption-text {
  font-size: 90%;
  color: #666;
  padding: 0.625rem 0; }

.alignleft {
  float: left;
  padding-right: 1rem;
  margin: 0; }

.alignright {
  float: right;
  padding-left: 1rem;
  margin: 0; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

/* site logo */
.site-logo {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 100; }
  .site-logo img {
    height: 100px;
    -webkit-transition: all; }

.dropdown.menu .is-active > a {
  background: transparent !important;
  color: #000 !important;
  opacity: 1.0 !important; }

.dropdown a:hover {
  background: none !important;
  background-color: none !important; }

.active .dropdown.menu .is-active:after {
  content: url("") !important;
  top: 0;
  display: none; }

.top-navigation-bar {
  display: none; }
  @media print, screen and (min-width: 64em) {
    .top-navigation-bar {
      display: block; } }

/* socials */
#socials {
  /*display: none;
  @include breakpoint(large) {
    display: block;
  }*/
  opacity: 0.6 !important;
  padding: 5px 45px 0 0; }
  @media print, screen and (min-width: 64em) {
    #socials {
      padding: 15px 15px 0 0; } }
  #socials a {
    color: #777;
    opacity: 1;
    font-size: 1.125rem;
    transition: all 0.2s ease-in-out; }
  #socials a:hover {
    opacity: 1.0;
    color: #000; }
  #socials a:not(:last-child) {
    margin-right: 10px; }

/* end socials */
/* building blocks */
.overlay-nav-menu-toggle {
  display: block;
  position: fixed;
  font-size: 1.375rem;
  z-index: 5000;
  color: #000;
  top: 18px;
  right: 15px;
  transition: all 0.4s ease-in-out; }
  @media print, screen and (min-width: 64em) {
    .overlay-nav-menu-toggle {
      display: none; } }
  .overlay-nav-menu-toggle:hover {
    /*transition: all 0.4s ease-in-out;
    color: lighten($overlay-nav-menu-toggle-color, 30%);*/ }

.overlay-nav-menu {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  list-style: none;
  /*background: $overlay-nav-menu-bg;*/
  background: #000;
  background-color: rgba(255, 255, 255, 0.9) !important;
  background-size: cover;
  text-align: center;
  transform: translateX(-100%) rotateX(40deg);
  transition: all 400ms cubic-bezier(1, -0.4, 0.44, 0.985); }
  .overlay-nav-menu li {
    font-size: 2.5rem; }
  .overlay-nav-menu a {
    display: block;
    color: #000;
    font-weight: normal;
    height: 6.25rem;
    line-height: 6.25rem;
    /*border-bottom: $overlay-nav-menu-link-border;*/
    text-decoration: none; }
    .overlay-nav-menu a:hover {
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff; }
  .overlay-nav-menu.is-open {
    top: 0;
    opacity: 1;
    transform: perspective(200px) translateX(0) rotateX(0); }
  .overlay-nav-menu.is-open ~ .overlay-nav-menu-toggle {
    display: inline-block;
    transition: all 0.4s ease-in-out;
    transform: rotate(135deg);
    color: white; }

/* end building blocks */
.top-bar, .top-bar ul {
  background-color: transparent; }

.site-header {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 12;
  width: 100%;
  /*background: $black;*/
  padding-top: 0; }
  .site-header .logo {
    -webkit-transition: all 1s ease;
    height: auto;
    width: 280px;
    max-width: 435px;
    -webkit-transition: all 1s ease;
    margin-top: 4px;
    margin-left: -5px; }
    @media screen and (max-width: 39.9375em) {
      .site-header .logo {
        margin-top: 5px !important;
        width: 260px !important; } }

.logo {
  -webkit-transition: all 1s ease;
  height: auto;
  width: 100%;
  max-width: 135px;
  -webkit-transition: all 1s ease;
  margin-top: 20px;
  margin-left: 20px; }
  @media screen and (max-width: 39.9375em) {
    .logo {
      margin-top: 20px !important; } }

.active {
  background-color: #fff !important;
  -webkit-box-shadow: 0px 24px 70px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 24px 70px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 24px 70px 0px rgba(0, 0, 0, 0.15); }

.active_small {
  /*width:100% !important;
  margin-top: 5px !important;
  max-width:200px !important;*/ }

.active_menu {
  margin-top: 0px !important; }

@media print, screen and (min-width: 40em) {
  .mobile-menu,
  .site-title-bar {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .desktop-menu,
  .site-navigation .top-bar-left {
    display: none !important; } }

.site-desktop-title a {
  font-weight: bold; }

#overlay-nav-menu .menu .is-active > a {
  background: none !important;
  color: #000 !important; }

.mobile-menu {
  display: none; }

.off-canvas > ul.menu {
  height: 100vh;
  padding: 1rem; }
  .off-canvas > ul.menu a {
    color: #fff;
    font-weight: 600;
    font-size: 0.9375rem; }

.off-canvas .menu .active > a {
  background-color: #ccc; }

.title-bar-title a {
  font-size: 1rem;
  color: #B9B9B9; }

.overlay-nav-menu .menu .is-active > a, .mobile-menu .menu .is-active > a,
.mobile-off-canvas-menu .menu .is-active > a {
  background-color: none; }

.top-bar {
  background-color: #fff !important;
  box-shadow: 0px 14px 70px 0px rgba(0, 0, 0, 0.45);
  /*.menu li:not(:last-child) { border-right: 1px solid #4e4e4e; }*/ }
  .top-bar .top-bar-title a {
    font-size: 1rem;
    color: #B9B9B9;
    padding-left: 1rem;
    line-height: 1.8; }
  .top-bar .menu {
    width: 100%;
    margin: auto;
    justify-content: center;
    margin-top: 0px; }
  .top-bar .menu a {
    color: #777;
    opacity: 1;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 700;
    font-size: 1.0rem;
    line-height: 1;
    transition: all 0.2s ease-in-out; }
    .top-bar .menu a:hover:not(.button) {
      background-color: #000; }
  .top-bar .menu .active > a {
    background-color: #ccc; }
  .top-bar .menu > li:not(.menu-text) > a {
    padding: 1.5rem 1rem 2rem 1rem;
    margin: 0 .5rem; }
  .top-bar .menu li:not(:last-child) {
    border-right: 0px solid #4e4e4e; }
  .top-bar .dropdown.menu .submenu {
    border: 0;
    background: #000 !important; }
  .top-bar .dropdown.menu .has-submenu.is-down-arrow a {
    padding-right: 0.5rem; }
  .top-bar .dropdown.menu .has-submenu.is-down-arrow > a::after {
    border: 0; }
  .top-bar .dropdown.menu:first-child > li.is-dropdown-submenu-parent > a::after {
    display: none;
    margin-top: -20px; }

@media screen and (max-width: 39.9375em) {
  .site-navigation {
    padding: 0; } }

.post-navigation::before, .post-navigation::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.post-navigation::after {
  clear: both; }

.nav-previous {
  float: left; }

.nav-next {
  float: right; }

/* hover */
/*Sweep To Right*/
.top-bar .menu li a, .hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s; }

.top-bar .menu li a:before, .hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20px;
  background: none;
  border-bottom: 0px solid #000;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.top-bar .menu li a:hover, .hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
  color: #000 !important;
  opacity: 1 !important;
  text-decoration: none !important;
  background: none !important; }

.top-bar .menu li a:hover:before, .hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1); }

/* end hover */
.sections {
  min-height: 100vh;
  overflow: visible; }
  .sections-90 {
    min-height: 90vh;
    overflow: visible; }
  .sections-80 {
    min-height: 80vh;
    overflow: visible; }
  .sections-60 {
    min-height: 60vh;
    overflow: visible; }

.page-id-32 #scamp_player, .page-id-34 #scamp_player {
  display: none !important; }

.thumb-artist {
  position: relative;
  width: 100%;
  height: 65vh;
  float: left;
  margin-top: 0;
  margin-bottom: 20px;
  max-height: 550px;
  overflow: hidden; }

.main-wrap {
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  margin-top: 1rem; }
  @media print, screen and (min-width: 40em) {
    .main-wrap {
      margin-top: 3rem; } }
  .main-wrap.sidebar-left .main-content {
    order: 1; }
  .main-wrap.sidebar-left .sidebar {
    order: 2; }
  @media print, screen and (min-width: 40em) {
    .main-wrap.sidebar-left .main-content {
      order: 2; }
    .main-wrap.sidebar-left .sidebar {
      order: 1; } }
  .main-wrap.full-width .main-content {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
  .main-wrap .main-content {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .main-wrap .main-content {
        width: calc(66.66667% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem; } }
  .main-wrap .sidebar {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .main-wrap .sidebar {
        width: calc(33.33333% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem;
        margin-top: 1rem; } }

label {
  color: #777; }

[type='password'] {
  max-width: 300px;
  margin: auto; }

[type='submit'], [type='button'] {
  background: #000;
  color: #fff;
  padding: 10px 40px;
  margin-top: 20px; }

.thumb {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  float: left;
  overflow: hidden;
  background: #000; }

@media (max-width: 767px) {
  .thumb {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
    float: none;
    overflow: hidden;
    display: block;
    text-align: center; }
  .lineup_decorations {
    display: none; }
  .days_header h2 {
    margin: 0;
    padding: 10px 0; } }

.text {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-weight: 800;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: #fff; }

.text img {
  height: 100px;
  margin-top: 10%;
  display: table-cell;
  margin: auto;
  vertical-align: middle;
  margin-top: 15%; }

.thumb:hover .yellow2 {
  display: block;
  background: #000; }

.thumb:hover .border-white {
  opacity: 0.7; }

.thumb:hover .orange2 {
  display: block;
  background: #000; }

.thumb a, .thumb a:visited {
  color: #fff;
  text-decoration: none;
  font-size: 19px; }

.thumb a:hover {
  color: #fff !important; }

.bit-widget .bit-nav-bar-container .bit-nav-bar {
  display: none !important; }

.bit-widget {
  background-color: transparent !important; }

.bit-widget.bit-layout-row .bit-event .bit-details .bit-date {
  white-space: normal !important;
  text-align: center;
  flex: none;
  border: 2px solid #000;
  width: 70px;
  height: 70px;
  padding: 20px;
  font-size: 20px;
  margin-right: 20px;
  background: #fff; }

.bit-widget.bit-layout-row .bit-event .bit-details .bit-venue {
  text-align: left; }

@media screen and (max-width: 39.9375em) {
  .bit-widget .bit-date {
    font-weight: bold;
    width: 100%;
    text-align: left;
    font-size: 30px;
    margin-bottom: 10px; }
  .bit-events-container {
    text-align: left; } }

.gform_wrapper form {
  max-width: 600px !important; }

.gform_wrapper textarea, .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
  font-size: inherit;
  font-family: inherit;
  padding: 5px 4px;
  letter-spacing: normal;
  width: 100%;
  background: #fff;
  color: #000;
  border: 1px solid #000; }

/**** IMAGES 88/

.grid {
  position: relative;
  clear: both;
  margin: 0 auto;
  padding: 1em 0 4em;
  max-width: 1000px;
  list-style: none;
  text-align: center;
}

/* Common style */
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 0;
  min-width: 120px;
  max-width: 880px;
  max-height: 460px;
  width: 100%;
  height: auto;
  background: #000;
  text-align: center;
  cursor: pointer; }

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  width: 100%;
  opacity: 1; }

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none; }

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0; }

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300; }

.grid figure h2 span {
  font-weight: 800; }

.grid figure h2,
.grid figure p {
  margin: 0; }

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%; }

/* Individual effects */
/*---------------*/
/***** Lily *****/
/*---------------*/
figure.effect-lily img {
  max-width: none;
  width: -webkit-calc(100% + 50px);
  width: calc(100% + 50px);
  opacity: 0.7;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-40px, 0, 0);
  transform: translate3d(-40px, 0, 0); }

figure.effect-lily figcaption {
  text-align: left; }

figure.effect-lily figcaption > div {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  height: 50%; }

figure.effect-lily h2,
figure.effect-lily p {
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0); }

figure.effect-lily h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s; }

figure.effect-lily p {
  color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
  transition: opacity 0.2s, transform 0.35s; }

figure.effect-lily:hover img,
figure.effect-lily:hover p {
  opacity: 1; }

figure.effect-lily:hover img,
figure.effect-lily:hover h2,
figure.effect-lily:hover p {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-lily:hover p {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s; }

/*---------------*/
/***** Sadie *****/
/*---------------*/
figure.effect-sadie figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(top, rgba(72, 76, 97, 0) 0%, rgba(72, 76, 97, 0.8) 75%);
  background: linear-gradient(to bottom, rgba(72, 76, 97, 0) 0%, rgba(72, 76, 97, 0.8) 75%);
  content: '';
  opacity: 0;
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0); }

figure.effect-sadie h2 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #484c61;
  -webkit-transition: -webkit-transform 0.35s, color 0.35s;
  transition: transform 0.35s, color 0.35s;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0); }

figure.effect-sadie figcaption::before,
figure.effect-sadie p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-sadie p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  opacity: 0;
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0); }

figure.effect-sadie:hover h2 {
  color: #fff;
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -40px, 0);
  transform: translate3d(0, -50%, 0) translate3d(0, -40px, 0); }

figure.effect-sadie:hover figcaption::before,
figure.effect-sadie:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Roxy *****/
/*---------------*/
figure.effect-roxy {
  background: -webkit-linear-gradient(45deg, #ff89e9 0%, #05abe0 100%);
  background: linear-gradient(45deg, #ff89e9 0%, #05abe0 100%); }

figure.effect-roxy img {
  max-width: none;
  width: -webkit-calc(100% + 60px);
  width: calc(100% + 60px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50px, 0, 0);
  transform: translate3d(-50px, 0, 0); }

figure.effect-roxy figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-20px, 0, 0);
  transform: translate3d(-20px, 0, 0); }

figure.effect-roxy figcaption {
  padding: 3em;
  text-align: left; }

figure.effect-roxy h2 {
  padding: 30% 0 10px 0; }

figure.effect-roxy p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

figure.effect-roxy:hover img {
  opacity: 0.7;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-roxy:hover figcaption::before,
figure.effect-roxy:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Bubba *****/
/*---------------*/
figure.effect-bubba {
  background: #9e5406; }

figure.effect-bubba img {
  opacity: 0.7;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

figure.effect-bubba:hover img {
  opacity: 0.4; }

figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-bubba figcaption::before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1); }

figure.effect-bubba figcaption::after {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0); }

figure.effect-bubba h2 {
  padding-top: 30%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0); }

figure.effect-bubba p {
  padding: 20px 2.5em;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); }

figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

figure.effect-bubba:hover h2,
figure.effect-bubba:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Romeo *****/
/*---------------*/
figure.effect-romeo {
  -webkit-perspective: 1000px;
  perspective: 1000px; }

figure.effect-romeo img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 0, 300px);
  transform: translate3d(0, 0, 300px); }

figure.effect-romeo:hover img {
  opacity: 0.6;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-romeo figcaption::before,
figure.effect-romeo figcaption::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 1px;
  background: #fff;
  content: '';
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

figure.effect-romeo:hover figcaption::before {
  opacity: 0.5;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
  transform: translate3d(-50%, -50%, 0) rotate(45deg); }

figure.effect-romeo:hover figcaption::after {
  opacity: 0.5;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  transform: translate3d(-50%, -50%, 0) rotate(-45deg); }

figure.effect-romeo h2,
figure.effect-romeo p {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s; }

figure.effect-romeo h2 {
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -150%, 0);
  transform: translate3d(0, -50%, 0) translate3d(0, -150%, 0); }

figure.effect-romeo p {
  padding: 0.25em 2em;
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, 150%, 0);
  transform: translate3d(0, -50%, 0) translate3d(0, 150%, 0); }

figure.effect-romeo:hover h2 {
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -100%, 0);
  transform: translate3d(0, -50%, 0) translate3d(0, -100%, 0); }

figure.effect-romeo:hover p {
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, 100%, 0);
  transform: translate3d(0, -50%, 0) translate3d(0, 100%, 0); }

/*---------------*/
/***** Layla *****/
/*---------------*/
figure.effect-layla {
  background: #18a367; }

figure.effect-layla img {
  height: 390px; }

figure.effect-layla figcaption {
  padding: 3em; }

figure.effect-layla figcaption::before,
figure.effect-layla figcaption::after {
  position: absolute;
  content: '';
  opacity: 0; }

figure.effect-layla figcaption::before {
  top: 50px;
  right: 30px;
  bottom: 50px;
  left: 30px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

figure.effect-layla figcaption::after {
  top: 30px;
  right: 50px;
  bottom: 30px;
  left: 50px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0; }

figure.effect-layla h2 {
  padding-top: 26%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s; }

figure.effect-layla p {
  padding: 0.5em 2em;
  text-transform: none;
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

figure.effect-layla img,
figure.effect-layla h2 {
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0); }

figure.effect-layla img,
figure.effect-layla figcaption::before,
figure.effect-layla figcaption::after,
figure.effect-layla p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-layla:hover img {
  opacity: 0.7;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-layla:hover figcaption::before,
figure.effect-layla:hover figcaption::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

figure.effect-layla:hover h2,
figure.effect-layla:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-layla:hover figcaption::after,
figure.effect-layla:hover h2,
figure.effect-layla:hover p,
figure.effect-layla:hover img {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s; }

/*---------------*/
/***** Honey *****/
/*---------------*/
figure.effect-honey {
  background: #4a3753; }

figure.effect-honey img {
  opacity: 0.9;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

figure.effect-honey:hover img {
  opacity: 0.5; }

figure.effect-honey figcaption::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: #fff;
  content: '';
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0); }

figure.effect-honey h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em 1.5em;
  width: 100%;
  text-align: left;
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0); }

figure.effect-honey h2 i {
  font-style: normal;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0); }

figure.effect-honey figcaption::before,
figure.effect-honey h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s; }

figure.effect-honey:hover figcaption::before,
figure.effect-honey:hover h2,
figure.effect-honey:hover h2 i {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Oscar *****/
/*---------------*/
figure.effect-oscar {
  background: -webkit-linear-gradient(45deg, #22682a 0%, #9b4a1b 40%, #3a342a 100%);
  background: linear-gradient(45deg, #22682a 0%, #9b4a1b 40%, #3a342a 100%); }

figure.effect-oscar img {
  opacity: 0.9;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

figure.effect-oscar figcaption {
  padding: 3em;
  background-color: rgba(58, 52, 42, 0.7);
  -webkit-transition: background-color 0.35s;
  transition: background-color 0.35s; }

figure.effect-oscar figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: ''; }

figure.effect-oscar h2 {
  margin: 20% 0 10px 0;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0); }

figure.effect-oscar figcaption::before,
figure.effect-oscar p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0);
  transform: scale(0); }

figure.effect-oscar:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-oscar:hover figcaption::before,
figure.effect-oscar:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

figure.effect-oscar:hover figcaption {
  background-color: rgba(58, 52, 42, 0); }

figure.effect-oscar:hover img {
  opacity: 0.4; }

/*---------------*/
/***** Marley *****/
/*---------------*/
figure.effect-marley figcaption {
  text-align: right; }

figure.effect-marley h2,
figure.effect-marley p {
  position: absolute;
  right: 30px;
  left: 30px;
  padding: 10px 0; }

figure.effect-marley p {
  bottom: 30px;
  line-height: 1.5;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0); }

figure.effect-marley h2 {
  top: 30px;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); }

figure.effect-marley:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-marley h2::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  background: #fff;
  content: '';
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0); }

figure.effect-marley h2::after,
figure.effect-marley p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-marley:hover h2::after,
figure.effect-marley:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Ruby *****/
/*---------------*/
figure.effect-ruby {
  background-color: #17819c; }

figure.effect-ruby img {
  opacity: 0.7;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.15);
  transform: scale(1.15); }

figure.effect-ruby:hover img {
  opacity: 0.5;
  -webkit-transform: scale(1);
  transform: scale(1); }

figure.effect-ruby h2 {
  margin-top: 20%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); }

figure.effect-ruby p {
  margin: 1em 0 0;
  padding: 3em;
  border: 1px solid #fff;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0) scale(1.1);
  transform: translate3d(0, 20px, 0) scale(1.1); }

figure.effect-ruby:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-ruby:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

/*---------------*/
/***** Milo *****/
/*---------------*/
figure.effect-milo {
  background: #2e5d5a; }

figure.effect-milo img {
  max-width: none;
  width: -webkit-calc(100% + 60px);
  width: calc(100% + 60px);
  opacity: 1;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-30px, 0, 0) scale(1.12);
  transform: translate3d(-30px, 0, 0) scale(1.12);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

figure.effect-milo:hover img {
  opacity: 0.5;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

figure.effect-milo h2 {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1em 1.2em; }

figure.effect-milo p {
  padding: 0 10px 0 0;
  width: 50%;
  border-right: 1px solid #fff;
  text-align: right;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-40px, 0, 0);
  transform: translate3d(-40px, 0, 0); }

figure.effect-milo:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Dexter *****/
/*---------------*/
figure.effect-dexter {
  background: -webkit-linear-gradient(top, #258dc8 0%, #683c13 100%);
  background: linear-gradient(to bottom, #258dc8 0%, #683c13 100%); }

figure.effect-dexter img {
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

figure.effect-dexter:hover img {
  opacity: 0.4; }

figure.effect-dexter figcaption::after {
  position: absolute;
  right: 30px;
  bottom: 30px;
  left: 30px;
  height: -webkit-calc(50% - 30px);
  height: calc(50% - 30px);
  border: 7px solid #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0); }

figure.effect-dexter:hover figcaption::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-dexter figcaption {
  padding: 3em;
  text-align: left; }

figure.effect-dexter p {
  position: absolute;
  right: 60px;
  bottom: 60px;
  left: 60px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0); }

figure.effect-dexter:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Sarah *****/
/*---------------*/
figure.effect-sarah {
  background: #42b078; }

figure.effect-sarah img {
  max-width: none;
  width: -webkit-calc(100% + 20px);
  width: calc(100% + 20px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

figure.effect-sarah:hover img {
  opacity: 0.4;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-sarah figcaption {
  text-align: left; }

figure.effect-sarah h2 {
  position: relative;
  overflow: hidden;
  padding: 0.5em 0; }

figure.effect-sarah h2::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

figure.effect-sarah:hover h2::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-sarah p {
  padding: 1em 0;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

figure.effect-sarah:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Zoe *****/
/*---------------*/
figure.effect-zoe figcaption {
  top: auto;
  bottom: 0;
  padding: 1em;
  height: 3.75em;
  background: #fff;
  color: #3c4a50;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0); }

figure.effect-zoe h2 {
  float: left; }

figure.effect-zoe p.icon-links a {
  float: right;
  color: #3c4a50;
  font-size: 1.4em; }

figure.effect-zoe:hover p.icon-links a:hover,
figure.effect-zoe:hover p.icon-links a:focus {
  color: #252d31; }

figure.effect-zoe p.description {
  position: absolute;
  bottom: 8em;
  padding: 2em;
  color: #fff;
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  -webkit-backface-visibility: hidden;
  /* Fix for Chrome 37.0.2062.120 (Mac) */ }

figure.effect-zoe h2,
figure.effect-zoe p.icon-links a {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 200%, 0);
  transform: translate3d(0, 200%, 0); }

figure.effect-zoe p.icon-links a span::before {
  display: inline-block;
  padding: 8px 10px;
  font-family: 'feathericons';
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-eye::before {
  content: '\e000'; }

.icon-paper-clip::before {
  content: '\e001'; }

.icon-heart::before {
  content: '\e024'; }

figure.effect-zoe h2 {
  display: inline-block; }

figure.effect-zoe:hover p.description {
  opacity: 1; }

figure.effect-zoe:hover figcaption,
figure.effect-zoe:hover h2,
figure.effect-zoe:hover p.icon-links a {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-zoe:hover h2 {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }

figure.effect-zoe:hover p.icon-links a:nth-child(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

figure.effect-zoe:hover p.icon-links a:nth-child(2) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s; }

figure.effect-zoe:hover p.icon-links a:first-child {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s; }

/*---------------*/
/***** Chico *****/
/*---------------*/
figure.effect-chico img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.12);
  transform: scale(1.12); }

figure.effect-chico:hover img {
  opacity: 0.5;
  -webkit-transform: scale(1);
  transform: scale(1); }

figure.effect-chico figcaption {
  padding: 3em; }

figure.effect-chico figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: '';
  -webkit-transform: scale(1.1);
  transform: scale(1.1); }

figure.effect-chico figcaption::before,
figure.effect-chico p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-chico h2 {
  padding: 20% 0 20px 0; }

figure.effect-chico p {
  margin: 0 auto;
  max-width: 200px;
  -webkit-transform: scale(1.5);
  transform: scale(1.5); }

figure.effect-chico:hover figcaption::before,
figure.effect-chico:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

/*---------------*/
/***** Chico Altered *****/
/*---------------*/
figure.effect-chico-altered img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.12);
  transform: scale(1.12); }

figure.effect-chico-altered:hover img {
  opacity: 0.2;
  -webkit-transform: scale(1);
  transform: scale(1); }

figure.effect-chico-altered figcaption {
  padding: 3em; }

figure.effect-chico-altered figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 0px solid #fff;
  content: '';
  -webkit-transform: scale(1.1);
  transform: scale(1.1); }

figure.effect-chico-altered figcaption::before,
figure.effect-chico-altered p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-chico-altered h2 {
  padding: 20% 0 20px 0; }

figure.effect-chico-altered p {
  margin: 0 auto;
  max-width: 200px;
  -webkit-transform: scale(1.5);
  transform: scale(1.5); }

figure.effect-chico-altered:hover figcaption::before,
figure.effect-chico-altered:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

/* Individual effects */
/*---------------*/
/***** Julia *****/
/*---------------*/
figure.effect-julia {
  background: #2f3238; }

figure.effect-julia img {
  max-width: none;
  height: 400px;
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, transform 1s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

figure.effect-julia figcaption {
  text-align: left; }

figure.effect-julia h2 {
  position: relative;
  padding: 0.5em 0; }

figure.effect-julia p {
  display: inline-block;
  margin: 0 0 0.25em;
  padding: 0.4em 1em;
  background: rgba(255, 255, 255, 0.9);
  color: #2f3238;
  text-transform: none;
  font-weight: 500;
  font-size: 75%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-360px, 0, 0);
  transform: translate3d(-360px, 0, 0); }

figure.effect-julia p:first-child {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s; }

figure.effect-julia p:nth-of-type(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

figure.effect-julia p:nth-of-type(3) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }

figure.effect-julia:hover p:first-child {
  -webkit-transition-delay: 0s;
  transition-delay: 0s; }

figure.effect-julia:hover p:nth-of-type(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }

figure.effect-julia:hover p:nth-of-type(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

figure.effect-julia:hover img {
  opacity: 0.4;
  -webkit-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1); }

figure.effect-julia:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*-----------------*/
/***** Goliath *****/
/*-----------------*/
figure.effect-goliath {
  background: #000; }

figure.effect-goliath img,
figure.effect-goliath h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s; }

figure.effect-goliath img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

figure.effect-goliath h2,
figure.effect-goliath p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px; }

figure.effect-goliath p {
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0); }

figure.effect-goliath:hover img {
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0); }

figure.effect-goliath:hover h2 {
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0); }

figure.effect-goliath:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*-----------------*/
/***** Hera *****/
/*-----------------*/
figure.effect-hera {
  background: #303fa9; }

figure.effect-hera h2 {
  font-size: 158.75%; }

figure.effect-hera h2,
figure.effect-hera p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%; }

figure.effect-hera figcaption::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  border: 2px solid #fff;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(0, 0, 1);
  transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(0, 0, 1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%; }

figure.effect-hera p {
  width: 100px;
  text-transform: none;
  font-size: 121%;
  line-height: 2; }

figure.effect-hera p a {
  color: #fff; }

figure.effect-hera p a:hover,
figure.effect-hera p a:focus {
  opacity: 0.6; }

figure.effect-hera p a i {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-hera p a:first-child i {
  -webkit-transform: translate3d(-60px, -60px, 0);
  transform: translate3d(-60px, -60px, 0); }

figure.effect-hera p a:nth-child(2) i {
  -webkit-transform: translate3d(60px, -60px, 0);
  transform: translate3d(60px, -60px, 0); }

figure.effect-hera p a:nth-child(3) i {
  -webkit-transform: translate3d(-60px, 60px, 0);
  transform: translate3d(-60px, 60px, 0); }

figure.effect-hera p a:nth-child(4) i {
  -webkit-transform: translate3d(60px, 60px, 0);
  transform: translate3d(60px, 60px, 0); }

figure.effect-hera:hover figcaption::before {
  opacity: 1;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
  transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1); }

figure.effect-hera:hover h2 {
  opacity: 0;
  -webkit-transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1); }

figure.effect-hera:hover p i:empty {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* just because it's stronger than nth-child */
  opacity: 1; }

/*-----------------*/
/***** Winston *****/
/*-----------------*/
figure.effect-winston {
  background: #162633;
  text-align: left; }

figure.effect-winston img {
  -webkit-transition: opacity 0.45s;
  transition: opacity 0.45s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

figure.effect-winston figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/triangle.svg) no-repeat center center;
  background-size: 100% 100%;
  content: '';
  -webkit-transition: opacity 0.45s, -webkit-transform 0.45s;
  transition: opacity 0.45s, transform 0.45s;
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%; }

figure.effect-winston h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); }

figure.effect-winston p {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 1.5em 7% 0; }

figure.effect-winston a {
  margin: 0 10px;
  color: #5d504f;
  font-size: 170%; }

figure.effect-winston a:hover,
figure.effect-winston a:focus {
  color: #cc6055; }

figure.effect-winston p a i {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0); }

figure.effect-winston:hover img {
  opacity: 0.6; }

figure.effect-winston:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-winston:hover figcaption::before {
  opacity: 0.7;
  -webkit-transform: rotate3d(0, 0, 1, 20deg);
  transform: rotate3d(0, 0, 1, 20deg); }

figure.effect-winston:hover p i {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-winston:hover p a:nth-child(3) i {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }

figure.effect-winston:hover p a:nth-child(2) i {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

figure.effect-winston:hover p a:first-child i {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s; }

/*-----------------*/
/***** Selena *****/
/*-----------------*/
figure.effect-selena {
  background: #fff; }

figure.effect-selena img {
  opacity: 0.95;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

figure.effect-selena:hover img {
  -webkit-transform: scale3d(0.95, 0.95, 1);
  transform: scale3d(0.95, 0.95, 1); }

figure.effect-selena h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); }

figure.effect-selena p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 90deg);
  transform: perspective(1000px) rotate3d(1, 0, 0, 90deg);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%; }

figure.effect-selena:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-selena:hover p {
  opacity: 1;
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 0);
  transform: perspective(1000px) rotate3d(1, 0, 0, 0); }

/*-----------------*/
/***** Terry *****/
/*-----------------*/
figure.effect-terry {
  background: #34495e; }

figure.effect-terry figcaption {
  padding: 1em; }

figure.effect-terry figcaption::before,
figure.effect-terry figcaption::after {
  position: absolute;
  width: 200%;
  height: 200%;
  border-style: solid;
  border-color: #101010;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s; }

figure.effect-terry figcaption::before {
  right: 0;
  bottom: 0;
  border-width: 0 70px 60px 0;
  -webkit-transform: translate3d(70px, 60px, 0);
  transform: translate3d(70px, 60px, 0); }

figure.effect-terry figcaption::after {
  top: 0;
  left: 0;
  border-width: 15px 0 0 15px;
  -webkit-transform: translate3d(-15px, -15px, 0);
  transform: translate3d(-15px, -15px, 0); }

figure.effect-terry img,
figure.effect-terry p a {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-terry img {
  opacity: 0.85; }

figure.effect-terry h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.4em 10px;
  width: 50%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

@media screen and (max-width: 920px) {
  figure.effect-terry h2 {
    padding: 0.75em 10px;
    font-size: 120%; } }

figure.effect-terry p {
  float: right;
  clear: both;
  text-align: left;
  text-transform: none;
  font-size: 111%; }

figure.effect-terry p a {
  display: block;
  margin-bottom: 1em;
  color: #fff;
  opacity: 0;
  -webkit-transform: translate3d(90px, 0, 0);
  transform: translate3d(90px, 0, 0); }

figure.effect-terry p a:hover,
figure.effect-terry p a:focus {
  color: #f3cf3f; }

figure.effect-terry:hover figcaption::before,
figure.effect-terry:hover figcaption::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-terry:hover img {
  opacity: 0.6; }

figure.effect-terry:hover h2,
figure.effect-terry:hover p a {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-terry:hover p a {
  opacity: 1; }

figure.effect-terry:hover p a:first-child {
  -webkit-transition-delay: 0.025s;
  transition-delay: 0.025s; }

figure.effect-terry:hover p a:nth-child(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }

figure.effect-terry:hover p a:nth-child(3) {
  -webkit-transition-delay: 0.075s;
  transition-delay: 0.075s; }

figure.effect-terry:hover p a:nth-child(4) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

/*-----------------*/
/***** Phoebe *****/
/*-----------------*/
figure.effect-phoebe {
  background: #675983; }

figure.effect-phoebe img {
  opacity: 0.85;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

figure.effect-phoebe:hover img {
  opacity: 0.6; }

figure.effect-phoebe figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/triangle2.svg) no-repeat center center;
  background-size: 100% 100%;
  content: '';
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(5, 2.5, 1);
  transform: scale3d(5, 2.5, 1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

figure.effect-phoebe:hover figcaption::before {
  opacity: 0.6;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

figure.effect-phoebe h2 {
  margin-top: 1em;
  -webkit-transition: transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0); }

figure.effect-phoebe:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-phoebe p a {
  color: #fff;
  font-size: 140%;
  opacity: 0;
  position: relative;
  display: inline-block;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-phoebe p a:first-child {
  -webkit-transform: translate3d(-60px, -60px, 0);
  transform: translate3d(-60px, -60px, 0); }

figure.effect-phoebe p a:nth-child(2) {
  -webkit-transform: translate3d(0, 60px, 0);
  transform: translate3d(0, 60px, 0); }

figure.effect-phoebe p a:nth-child(3) {
  -webkit-transform: translate3d(60px, -60px, 0);
  transform: translate3d(60px, -60px, 0); }

figure.effect-phoebe:hover p a {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*-----------------*/
/***** Apollo *****/
/*-----------------*/
figure.effect-apollo {
  background: #3498db; }

figure.effect-apollo img {
  opacity: 0.95;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.05, 1.05, 1);
  transform: scale3d(1.05, 1.05, 1); }

figure.effect-apollo figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  content: '';
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0); }

figure.effect-apollo p {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 3em;
  padding: 0 1em;
  max-width: 150px;
  border-right: 4px solid #fff;
  text-align: right;
  opacity: 0;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

figure.effect-apollo h2 {
  text-align: left; }

figure.effect-apollo:hover img {
  opacity: 0.6;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

figure.effect-apollo:hover figcaption::before {
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0); }

figure.effect-apollo:hover p {
  opacity: 1;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

/*-----------------*/
/***** Kira *****/
/*-----------------*/
figure.effect-kira {
  background: #fff;
  text-align: left; }

figure.effect-kira img {
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

figure.effect-kira figcaption {
  z-index: 1; }

figure.effect-kira p {
  padding: 2.25em 0.5em;
  font-weight: 600;
  font-size: 100%;
  line-height: 1.5;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

figure.effect-kira p a {
  margin: 0 0.5em;
  color: #101010; }

figure.effect-kira p a:hover,
figure.effect-kira p a:focus {
  opacity: 0.6; }

figure.effect-kira figcaption::before {
  position: absolute;
  top: 0;
  right: 2em;
  left: 2em;
  z-index: -1;
  height: 3.5em;
  background: #fff;
  content: '';
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 4em, 0) scale3d(1, 0.023, 1);
  transform: translate3d(0, 4em, 0) scale3d(1, 0.023, 1);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0; }

figure.effect-kira:hover img {
  opacity: 0.5; }

figure.effect-kira:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-kira:hover figcaption::before {
  opacity: 0.7;
  -webkit-transform: translate3d(0, 5em, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 5em, 0) scale3d(1, 1, 1); }

/*-----------------*/
/***** Steve *****/
/*-----------------*/
figure.effect-steve {
  z-index: auto;
  overflow: visible;
  background: #000; }

figure.effect-steve:before,
figure.effect-steve h2:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000;
  content: '';
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

figure.effect-steve:before {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.8);
  opacity: 0; }

figure.effect-steve figcaption {
  z-index: 1; }

figure.effect-steve img {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: perspective(1000px) translate3d(0, 0, 0);
  transform: perspective(1000px) translate3d(0, 0, 0); }

figure.effect-steve h2,
figure.effect-steve p {
  background: #fff;
  color: #2d434e; }

figure.effect-steve h2 {
  position: relative;
  margin-top: 2em;
  padding: 0.25em; }

figure.effect-steve h2:before {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5); }

figure.effect-steve p {
  margin-top: 1em;
  padding: 0.5em;
  font-weight: 800;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1); }

figure.effect-steve:hover:before {
  opacity: 1; }

figure.effect-steve:hover img {
  -webkit-transform: perspective(1000px) translate3d(0, 0, 21px);
  transform: perspective(1000px) translate3d(0, 0, 21px); }

figure.effect-steve:hover h2:before {
  opacity: 0; }

figure.effect-steve:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/*-----------------*/
/***** Moses *****/
/*-----------------*/
figure.effect-moses {
  background: -webkit-linear-gradient(-45deg, #EC65B7 0%, #05E0D8 100%);
  background: linear-gradient(-45deg, #EC65B7 0%, #05E0D8 100%); }

figure.effect-moses img {
  opacity: 0.85;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

figure.effect-moses h2,
figure.effect-moses p {
  padding: 20px;
  width: 50%;
  height: 50%;
  border: 2px solid #fff; }

figure.effect-moses h2 {
  padding: 20px;
  width: 50%;
  height: 50%;
  text-align: left;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(10px, 10px, 0);
  transform: translate3d(10px, 10px, 0); }

figure.effect-moses p {
  float: right;
  padding: 20px;
  text-align: right;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

figure.effect-moses:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-moses:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-moses:hover img {
  opacity: 0.6; }

/*---------------*/
/***** Jazz *****/
/*---------------*/
figure.effect-jazz {
  background: -webkit-linear-gradient(-45deg, #f3cf3f 0%, #f33f58 100%);
  background: linear-gradient(-45deg, #f3cf3f 0%, #f33f58 100%); }

figure.effect-jazz img {
  opacity: 0.9; }

figure.effect-jazz figcaption::after,
figure.effect-jazz img,
figure.effect-jazz p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-jazz figcaption::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  content: '';
  opacity: 0;
  -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
  transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

figure.effect-jazz h2,
figure.effect-jazz p {
  opacity: 1;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1); }

figure.effect-jazz h2 {
  padding-top: 26%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s; }

figure.effect-jazz p {
  padding: 0.5em 2em;
  text-transform: none;
  font-size: 0.85em;
  opacity: 0; }

figure.effect-jazz:hover img {
  opacity: 0.7;
  -webkit-transform: scale3d(1.05, 1.05, 1);
  transform: scale3d(1.05, 1.05, 1); }

figure.effect-jazz:hover figcaption::after {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);
  transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1); }

figure.effect-jazz:hover h2,
figure.effect-jazz:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/*---------------*/
/***** Ming *****/
/*---------------*/
figure.effect-ming {
  background: #030c17; }

figure.effect-ming img {
  opacity: 0.9;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

figure.effect-ming figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 2px solid #fff;
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.4, 1.4, 1);
  transform: scale3d(1.4, 1.4, 1); }

figure.effect-ming h2 {
  margin: 20% 0 10px 0;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s; }

figure.effect-ming p {
  padding: 1em;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.5);
  transform: scale(1.5); }

figure.effect-ming:hover h2 {
  -webkit-transform: scale(0.9);
  transform: scale(0.9); }

figure.effect-ming:hover figcaption::before,
figure.effect-ming:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

figure.effect-ming:hover figcaption {
  background-color: rgba(58, 52, 42, 0); }

figure.effect-ming:hover img {
  opacity: 0.4; }

/*---------------*/
/***** Lexi *****/
/*---------------*/
figure.effect-lexi {
  background: -webkit-linear-gradient(-45deg, #000 0%, #fff 100%);
  background: linear-gradient(-45deg, #000 0%, #fff 100%); }

figure.effect-lexi img {
  margin: -10px 0 0 -10px;
  max-width: none;
  width: -webkit-calc(100% + 20px);
  width: calc(100% + 20px);
  opacity: 1;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(10px, 10px, 0);
  transform: translate3d(10px, 10px, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: auto; }

figure.effect-lexi figcaption::before,
figure.effect-lexi p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-lexi figcaption::before {
  position: absolute;
  right: -100px;
  bottom: -100px;
  width: 300px;
  height: 300px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 900px rgba(255, 255, 255, 0.2);
  content: '';
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 1);
  transform: scale3d(0.5, 0.5, 1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

figure.effect-lexi:hover img {
  opacity: 0.6;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-lexi h2 {
  text-align: left;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(5px, 5px, 0);
  transform: translate3d(5px, 5px, 0); }

figure.effect-lexi p {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 1.5em 1.5em 0;
  width: 160px;
  text-align: right;
  opacity: 0;
  -webkit-transform: translate3d(20px, 20px, 0);
  transform: translate3d(20px, 20px, 0); }

figure.effect-lexi:hover figcaption::before {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

figure.effect-lexi:hover h2,
figure.effect-lexi:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Duke *****/
/*---------------*/
figure.effect-duke {
  background: -webkit-linear-gradient(-45deg, #34495e 0%, #cc6055 100%);
  background: linear-gradient(-45deg, #34495e 0%, #cc6055 100%); }

figure.effect-duke img,
figure.effect-duke p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s; }

figure.effect-duke:hover img {
  opacity: 0.1;
  -webkit-transform: scale3d(2, 2, 1);
  transform: scale3d(2, 2, 1); }

figure.effect-duke h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%; }

figure.effect-duke p {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
  padding: 30px;
  border: 2px solid #fff;
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transform-origin: 50% -100%;
  transform-origin: 50% -100%; }

figure.effect-duke:hover h2,
figure.effect-duke:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/* Media queries */
@media screen and (max-width: 50em) {
  .content {
    padding: 0 10px;
    text-align: center; }
  .grid figure {
    display: inline-block;
    float: none;
    margin: 0 auto;
    width: 100%; } }

.footer-container {
  margin: 0 auto;
  /*margin-top: rem-calc(60);*/
  marign-top: 0;
  background: #fff;
  color: #000; }

.footer {
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  padding: 1.875rem 0;
  background: #fff;
  color: #000; }
  .footer a {
    color: #fff; }
  .footer h3 {
    font-weight: bold;
    font-size: 1.1875rem; }
  .footer .menu .is-active > a {
    background: #000;
    color: #000 !important; }
  .footer .menu a {
    background: #000;
    color: #fff; }
  .footer a:hover {
    color: #000 !important; }
  .footer article {
    flex: 1 1 0px;
    width: auto;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    margin: 0; }
  .footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }

body#tinymce {
  height: auto;
  max-width: 75rem;
  padding: 1.25rem !important; }

.sites-button {
  background: #000;
  font-size: 1.25rem;
  font-weight: bold;
  box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.2) inset;
  border-radius: 3px;
  border: none; }

.button {
  background-color: #000 !important; }

.large:hover {
  background-color: #000 !important; }

.transprent-button {
  background: transprent;
  border: 2px solid #fff;
  color: #fff;
  width: 200px;
  padding: 10px;
  display: block;
  text-align: center; }
  .transprent-button:hover {
    border: 2px solid #000;
    color: #000; }

.cards-container {
  display: flex;
  flex-flow: row wrap; }
  .cards-container .card {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    .cards-container .card .card-content {
      padding: 1rem; }
    @media print, screen and (min-width: 40em) {
      .cards-container .card {
        width: calc(33.33333% - 0.7rem);
        margin-right: 0.35rem;
        margin-left: 0.35rem; } }

.featured-hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 6.25rem; }
  @media print, screen and (min-width: 40em) {
    .featured-hero {
      height: 12.5rem; } }
  @media screen and (min-width: 75em) {
    .featured-hero {
      height: 25rem; } }

.page-header-title {
  min-height: 200px; }

.dotted, .section-divider hr {
  border-style: dashed;
  margin: 2rem 0; }

[data-whatinput="mouse"] a:focus, button:focus [data-whatinput="touch"] a:focus, button:focus {
  outline: none; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus, .slick-slide:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -45px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 0; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

#background {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

#main_content {
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  z-index: -2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  position: fixed; }
  @media print, screen and (min-width: 64em) {
    #main_content {
      height: 100%;
      position: fixed;
      overflow: scroll; } }
  #main_content-transparent {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    z-index: -2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    position: fixed; }
  #main_content h1 {
    font-weight: bold; }

.home_feature a {
  color: #fff !important; }

.home_feature a:hover {
  color: #fff !important;
  opacity: 0.7; }

.main-content-opacity, .main_content_opacity {
  background: rgba(0, 0, 0, 0.7) !important; }

.body-content {
  text-align: center;
  margin-top: 8rem; }

.loading_image {
  margin: auto; }

.front-hero {
  /*background: url('../images/demo/hero-bg-foundation-6-small.svg') bottom center;*/
  background-size: cover;
  background-position: bottom;
  padding: 4.0625rem 0;
  margin: 0;
  height: auto;
  position: relative;
  text-align: left; }
  .front-hero .marketing {
    max-width: 55rem;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap; }
  .front-hero .test {
    background: none; }
  @media print, screen and (min-width: 40em) {
    .front-hero {
      /*background: url('../images/demo/hero-bg-foundation-6-large.svg') bottom center;*/
      background-size: cover;
      background-position: center;
      height: 42.8125rem;
      margin: 0 0 0; } }
  .front-hero .watch {
    width: calc(58.33333% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .front-hero .watch {
        width: calc(100% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem; } }
    .front-hero .watch a {
      color: #B4C9D1;
      cursor: pointer;
      font-weight: 400;
      margin-right: 1.25rem; }
    .front-hero .watch a:hover {
      color: #fff; }
    .front-hero .watch #stargazers :before {
      content: "\f09b";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: #B4C9D1;
      margin-right: 0.5rem; }
    .front-hero .watch #twitter :before {
      content: "\f099";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: #B4C9D1;
      margin-right: 0.5rem; }
  .front-hero .tagline {
    width: calc(66.66667% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .front-hero .tagline {
        width: calc(41.66667% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem;
        padding-top: 6rem; } }
  .front-hero h1 {
    color: #fff;
    font-weight: 500;
    font-size: 2.2rem; }
    @media print, screen and (min-width: 40em) {
      .front-hero h1 {
        font-size: 2.875rem; } }
  .front-hero h4 {
    color: #fefefe;
    font-weight: 300;
    font-size: 1.3125rem; }
  .front-hero .download {
    margin-top: 1.25rem; }

.mls-search {
  background: #000; }
  .mls-search input {
    background: none;
    background-color: transparent;
    border: 1px solid #fff; }

.intro {
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap; }
  .intro .fp-intro {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .intro .fp-intro {
        width: calc(83.33333% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem;
        margin-left: calc(8.33333% + 0.625rem); }
        .intro .fp-intro h2 {
          font-weight: 300;
          margin-bottom: 1.5rem; }
        .intro .fp-intro h4 {
          font-size: 1.125rem;
          line-height: 1.6;
          color: #777;
          margin-bottom: 2rem; } }

.contact-bar a {
  color: #000 !important; }

.contact-bar a:hover {
  text-decoration: underline; }

.section-divider {
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap; }
  .section-divider hr {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    box-sizing: border-box; }

.benefits {
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  text-align: center; }
  .benefits header {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    .benefits header h2 {
      font-weight: 300; }
    .benefits header h4 {
      font-size: 1.125rem;
      line-height: 1.6;
      color: #777; }
  .benefits .semantic,
  .benefits .responsive,
  .benefits .customizable,
  .benefits .professional {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .benefits .semantic,
      .benefits .responsive,
      .benefits .customizable,
      .benefits .professional {
        width: calc(25% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem; } }
    .benefits .semantic img,
    .benefits .responsive img,
    .benefits .customizable img,
    .benefits .professional img {
      padding: 1.25rem;
      margin: 1rem auto 0 auto; }
    .benefits .semantic h3,
    .benefits .responsive h3,
    .benefits .customizable h3,
    .benefits .professional h3 {
      color: #0a0a0a;
      font-weight: 300;
      font-size: 1.75rem; }
    .benefits .semantic p,
    .benefits .responsive p,
    .benefits .customizable p,
    .benefits .professional p {
      font-size: 0.9375rem; }
  .benefits .why-foundation {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    margin-top: 4rem; }

.contact-bottom-right {
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 13px 35px;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }
  .contact-bottom-right a {
    color: #777 !important; }
  .contact-bottom-right [type='tel'] {
    color: #cc000 !important;
    text-decoration: none; }

.kitchen-sink-header {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem; }

.kitchen-sink-components {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem; }
  .kitchen-sink-components hr {
    margin: 3rem 0; }
  @media print, screen and (min-width: 64em) {
    .kitchen-sink-components {
      width: calc(75% - 1.875rem); } }

.kitchen-sink-nav {
  display: none; }
  @media print, screen and (min-width: 64em) {
    .kitchen-sink-nav {
      width: calc(25% - 1.875rem);
      display: block;
      float: right; }
      .kitchen-sink-nav .docs-sub-menu {
        font-size: 0.85rem;
        margin-top: 1rem; }
      .kitchen-sink-nav .docs-menu-title {
        text-transform: uppercase;
        font-size: 0.75rem;
        color: #777;
        font-weight: bold;
        line-height: 1;
        padding-left: 0.9rem;
        margin-bottom: 0.5rem;
        border-top: 1px solid #cacaca;
        margin-top: 1rem;
        padding-top: 1.5rem; }
      .kitchen-sink-nav .docs-toc {
        width: 100%;
        padding-left: 4rem;
        padding-right: 1rem; }
      .kitchen-sink-nav .docs-toc .active {
        background: #2199e8;
        color: white; }
      .kitchen-sink-nav .docs-toc .docs-sub-menu:first-child .docs-menu-title {
        margin-top: 0;
        border-top: 0;
        padding-top: 0; } }

.kitchen-sink-grid .grid-x {
  background: #d6ecfa;
  font-size: 12px;
  line-height: 2rem;
  margin-bottom: 1.5rem; }
  .kitchen-sink-grid .grid-x .cell, .kitchen-sink-grid .grid-x :nth-child(odd) {
    background: #7bc1ef;
    color: #0a0a0a; }
  .kitchen-sink-grid .grid-x .cell:nth-child(even) {
    background: #1779ba;
    color: #fefefe; }

.background-color-primary {
  background-color: #000; }

.background-color-secondary {
  background-color: #000; }

.background-color-light-gray {
  background-color: #e7e7e7; }

.background-remove-color {
  filter: grayscale(1); }

.background-color-white {
  background-color: #fff; }

.background-color-black {
  background-color: #000; }

.background-cover {
  background-position: center center;
  background-size: cover; }
  .background-cover.position-left {
    background-position-x: left; }
  .background-cover.position-right {
    background-position-x: right; }
  .background-cover.position-top {
    background-position-y: top; }
  .background-cover.position-bottom {
    background-position-y: bottom; }

.full-width {
  width: 100%; }
  .full-width-override {
    width: 100% !important;
    max-width: 100% !important; }

.ninety-width {
  width: 90%; }
  .ninety-width-override {
    width: 90% !important;
    max-width: 90% !important; }

.eighty-width {
  width: 80%; }
  .eighty-width-override {
    width: 80% !important;
    max-width: 80% !important; }

.seventy-width {
  width: 70%; }
  .seventy-width-override {
    width: 70% !important;
    max-width: 70% !important; }

.sixty-width {
  width: 60%; }
  .sixty-width-override {
    width: 60% !important;
    max-width: 60% !important; }

.forty-width {
  width: 40%; }
  .forty-width-override {
    width: 40% !important;
    max-width: 40% !important; }

/* pixels */
.three-hudnred-pixels {
  width: 300px; }
  .three-hudnred-pixels-override {
    width: 300px !important;
    max-width: 300px !important; }

.two-hudnred-pixels {
  width: 200px; }
  .two-hudnred-pixels-override {
    width: 200px !important;
    max-width: 200px !important; }

.one-sixty-pixels {
  width: 160px; }
  .one-sixty-pixels-override {
    width: 160px !important;
    max-width: 160px !important; }

.one-fifty-pixels {
  width: 150px; }
  .one-fifty-pixels-override {
    width: 150px !important;
    max-width: 150px !important; }

.one-thirty-pixels {
  width: 130px; }
  .one-thirty-pixels-override {
    width: 130px !important;
    max-width: 130px !important; }

.sixty-pixels {
  width: 60px; }
  .sixty-pixels-override {
    width: 60px !important;
    max-width: 60px !important; }

.forty-pixels {
  width: 40px; }
  .forty-pixels-override {
    width: 40px !important;
    max-width: 40px !important; }

.forty-pixels-square {
  width: 40px;
  height: 40px; }
  .forty-pixels-square-override {
    width: 40px !important;
    max-width: 40px !important; }

.thirty-pixels {
  width: 30px; }
  .thirty-pixels-override {
    width: 30px !important;
    max-width: 30px !important; }

.twenty-pixels {
  width: 20px; }
  .twenty-pixels-override {
    width: 20px !important;
    max-width: 20px !important; }

.ten-pixels {
  width: 10px; }
  .ten-pixels-override {
    width: 10px !important;
    max-width: 10px !important; }

.font-weight-bold {
  font-weight: bold; }

::selection {
  background-color: #0d0d0d;
  color: #fff; }

@font-face {
  font-family: 'theartist';
  src: url("../fonts/made_theartist_sans_personal_use-webfont.woff2") format("woff2"), url("../fonts/made_theartist_sans_personal_use-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.secondary-font {
  font-family: helvetica, verdana; }

.color-primary {
  color: #000; }

.color-secondary {
  color: #000; }

.color-black {
  color: #000; }
  .color-black:hover {
    color: #000; }

.color-white {
  color: #fff; }

.color-body {
  color: #000; }

.color-grey {
  color: #666; }

.weight-normal {
  font-weight: normal; }

.weight-bold {
  font-weight: bold; }

.small_text {
  font-size: 80%; }

.small-text {
  font-size: 80%; }

.blue {
  color: #000; }

.color-blue {
  color: #000; }

.custom_gradient .blue {
  color: #fff; }

.uppercase {
  text-transform: uppercase; }

.font-size-twenty {
  font-size: 22px; }
  @media screen and (max-width: 39.9375em) {
    .font-size-twenty {
      font-size: 20px; } }

.font-size-sixteen {
  font-size: 16px; }
  @media screen and (max-width: 39.9375em) {
    .font-size-sixteen {
      font-size: 15px; } }

.margin-auto {
  margin: auto; }

.margin-top-tiny {
  margin-top: 0.625rem; }
  .margin-top-tiny-override {
    margin-top: 0.625rem !important; }

.margin-top-small {
  margin-top: 0.9375rem; }
  .margin-top-small-override {
    margin-top: 0.9375rem !important; }

.margin-top-medium {
  margin-top: 1.25rem; }
  .margin-top-medium-override {
    margin-top: 1.25rem !important; }

.margin-top-large {
  margin-top: 1.5625rem; }
  .margin-top-large-override {
    margin-top: 1.5625rem !important; }

.margin-top-xlarge {
  margin-top: 1.875rem; }
  .margin-top-xlarge-override {
    margin-top: 1.875rem !important; }

.margin-bottom-tiny {
  margin-bottom: 0.625rem; }
  .margin-bottom-tiny-override {
    margin-bottom: 0.625rem !important; }

.margin-bottom-small {
  margin-bottom: 0.9375rem; }
  .margin-bottom-small-override {
    margin-bottom: 0.9375rem !important; }

.margin-bottom-medium {
  margin-bottom: 1.25rem; }
  .margin-bottom-medium-override {
    margin-bottom: 1.25rem !important; }

.margin-bottom-large {
  margin-bottom: 1.5625rem; }
  .margin-bottom-large-override {
    margin-bottom: 1.5625rem !important; }

.margin-bottom-xlarge {
  margin-bottom: 1.875rem; }
  .margin-bottom-xlarge-override {
    margin-bottom: 1.875rem !important; }

.remove-last-paragraph-margin-bottom > p:last-of-type {
  margin-bottom: 0 !important; }

.remove-margin {
  margin: 0; }
  .remove-margin-override {
    margin: 0 !important; }
  @media screen and (max-width: 39.9375em) {
    .remove-margin-for-small {
      margin: 0; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .remove-margin-for-medium {
      margin: 0; } }
  @media print, screen and (min-width: 64em) {
    .remove-margin-for-large {
      margin: 0; } }

.remove-margin-top {
  margin-top: 0; }
  .remove-margin-top-override {
    margin-top: 0 !important; }
  @media screen and (max-width: 39.9375em) {
    .remove-margin-top-for-small {
      margin-top: 0; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .remove-margin-top-for-medium {
      margin-top: 0; } }
  @media print, screen and (min-width: 64em) {
    .remove-margin-top-for-large {
      margin-top: 0; } }

.remove-margin-right {
  margin-right: 0; }
  .remove-margin-right-override {
    margin-right: 0 !important; }
  @media screen and (max-width: 39.9375em) {
    .remove-margin-right-for-small {
      margin-right: 0; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .remove-margin-right-for-medium {
      margin-right: 0; } }
  @media print, screen and (min-width: 64em) {
    .remove-margin-right-for-large {
      margin-right: 0; } }

.remove-margin-bottom {
  margin-bottom: 0; }
  .remove-margin-bottom-override {
    margin-bottom: 0 !important; }
  @media screen and (max-width: 39.9375em) {
    .remove-margin-bottom-for-small {
      margin-bottom: 0; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .remove-margin-bottom-for-medium {
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .remove-margin-bottom-for-large {
      margin-bottom: 0; } }

.remove-margin-left {
  margin-left: 0; }
  .remove-margin-left-override {
    margin-left: 0 !important; }
  @media screen and (max-width: 39.9375em) {
    .remove-margin-left-for-small {
      margin-left: 0; }
    .remove-margin-left-for-small-override {
      margin-left: 0 !important; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .remove-margin-left-for-medium {
      margin-left: 0; }
    .remove-margin-left-for-medium-override {
      margin-left: 0 !important; } }
  @media print, screen and (min-width: 64em) {
    .remove-margin-left-for-large {
      margin-left: 0; }
    .remove-margin-left-for-large-override {
      margin-left: 0 !important; } }

.margin-minus-five {
  margin-top: -5px; }

.OL {
  outline: 1px solid salmon; }

.animation-delay-zero-three {
  -webkit-animation-delay: 0.3s; }

.animation-delay-zero-five {
  -webkit-animation-delay: 0.5s; }

.animation-delay-zero-seven {
  -webkit-animation-delay: 0.7s; }

.animation-delay-zero-nine {
  -webkit-animation-delay: 0.9s; }

.animation-duration-two {
  -webkit-animation-duration: 2s; }

.round-image {
  border-radius: 50%; }

.border-alternative {
  border: 3px solid #000;
  transition: all 0.2s ease; }
  .border-alternative:hover {
    border: 3px solid #fff; }

.border-white {
  border: 10px solid #fff;
  transition: all 0.2s ease; }
  .border-white:hover {
    border: 10px solid #000; }

.btn-border-clear {
  border: 10px solid #fff; }
  .btn-border-clear:hover {
    border: 10px solid #000; }

.border-black {
  border: 1px solid #000; }

.box-shadow {
  box-shadow: 0 12px 24px 0 rgba(137, 151, 199, 0.45); }

.border-radius-50 {
  border-radius: 50px; }

.image {
  position: relative;
  width: 100%;
  /* for IE 6 */
  background: #673ab7; }

.image img:hover {
  opacity: 0.3; }

.property-home img:hover {
  -webkit-filter: blur(5px); }

.outer-glow {
  -webkit-box-shadow: -7px 2px 29px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -7px 2px 29px 0px rgba(0, 0, 0, 0.35);
  box-shadow: -7px 2px 29px 0px rgba(0, 0, 0, 0.35); }

.learn-more button {
  letter-spacing: 1px;
  background: none;
  color: #000;
  position: relative;
  outline: none;
  border: none;
  height: 50px;
  width: 130px;
  font-size: 14px;
  z-index: 2;
  transition: .01s .23s ease-out all;
  overflow: hidden;
  text-align: left;
  padding-left: 15px; }
  .learn-more button:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 80%;
    background: #000;
    z-index: -1;
    transition: .3s ease-in all; }
  .learn-more button:after {
    content: '';
    position: absolute;
    left: -5%;
    top: 5%;
    height: 90%;
    width: 5%;
    background: white;
    z-index: -1;
    transition: .4s .02s ease-in all; }
  .learn-more button:hover {
    cursor: pointer;
    color: transparent; }
    .learn-more button:hover:before {
      left: 100%;
      width: 25%; }
    .learn-more button:hover:after {
      left: 100%;
      width: 70%; }
    .learn-more button:hover .icon-right.after:after {
      left: -80px;
      color: #000;
      transition: .2s .2s ease all; }
    .learn-more button:hover .icon-right.after:before {
      left: -104px;
      top: 14px;
      opacity: 0.4;
      color: #000; }

.icon-right {
  position: absolute;
  top: 0;
  right: 0; }
  .icon-right:after {
    font-family: "FontAwesome";
    content: '\2192';
    font-size: 24px;
    display: inline-block;
    position: relative;
    top: 26px;
    transform: translate3D(0, -50%, 0); }
  .icon-right.after:after {
    left: -250px;
    color: black;
    transition: .15s .25s ease left, .5s .05s ease color; }
  .icon-right.after:before {
    content: 'Learn More';
    position: absolute;
    left: -230px;
    top: 14px;
    opacity: 0;
    transition: .2s ease-in all; }

/*
.property-home h2, h3 {
   position: absolute;
   bottom: 0px;
   left: 3%;
   width: 97%;
}*/
.padding-default {
  padding: 2rem; }

.padding-default-small {
  padding: 0.5rem; }

.padding-top-tiny-tiny {
  padding-top: 0.4375rem; }

.padding-top-tiny {
  padding-top: 0.625rem; }

.padding-top-small {
  padding-top: 0.9375rem; }

.padding-top-medium {
  padding-top: 1.25rem; }

.padding-top-large {
  padding-top: 1.5625rem; }

.padding-top-xlarge {
  padding-top: 3.125rem; }

/*.padding-bottom-default {
    @include padding( 'default', 'bottom' );
}

.padding-bottom-reduced {
    @include padding( 'reduced', 'bottom' );
}

.padding-bottom-increased {
    @include padding( 'increased', 'bottom' );
}*/
.padding-bottom-tiny-tiny {
  padding-bottom: 0.4375rem; }

.padding-bottom-tiny {
  padding-bottom: 0.625rem; }

.padding-bottom-small {
  padding-bottom: 0.9375rem; }

.padding-bottom-medium {
  padding-bottom: 1.25rem; }

.padding-bottom-large {
  padding-bottom: 1.5625rem; }

.padding-bottom-xlarge {
  padding-bottom: 3.125rem; }

.remove-padding {
  padding: 0; }
  .remove-padding-override {
    padding: 0 !important; }
  @media screen and (max-width: 39.9375em) {
    .remove-padding-for-small {
      padding: 0; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .remove-padding-for-medium {
      padding: 0; } }
  @media print, screen and (min-width: 64em) {
    .remove-padding-for-large {
      padding: 0; } }
  @media screen and (min-width: 75em) {
    .remove-padding-global-width {
      padding-top: 0; } }

.remove-padding-top {
  padding-top: 0; }
  .remove-padding-top-override {
    padding-top: 0 !important; }
  @media screen and (max-width: 39.9375em) {
    .remove-padding-top-for-small {
      padding-top: 0; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .remove-padding-top-for-medium {
      padding-top: 0; } }
  @media print, screen and (min-width: 64em) {
    .remove-padding-top-for-large {
      padding-top: 0; } }
  @media screen and (min-width: 75em) {
    .remove-padding-top-global-width {
      padding-top: 0; } }

.remove-padding-right {
  padding-right: 0; }
  .remove-padding-right-override {
    padding-right: 0 !important; }
  @media screen and (max-width: 39.9375em) {
    .remove-padding-right-for-small {
      padding-right: 0; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .remove-padding-right-for-medium {
      padding-right: 0; } }
  @media print, screen and (min-width: 64em) {
    .remove-padding-right-for-large {
      padding-right: 0; } }
  @media screen and (min-width: 75em) {
    .remove-padding-right-global-width {
      padding-top: 0; } }

.remove-padding-bottom {
  padding-bottom: 0; }
  .remove-padding-bottom-override {
    padding-bottom: 0 !important; }
  @media screen and (max-width: 39.9375em) {
    .remove-padding-bottom-for-small {
      padding-bottom: 0; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .remove-padding-bottom-for-medium {
      padding-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .remove-padding-bottom-for-large {
      padding-bottom: 0; } }
  @media screen and (min-width: 75em) {
    .remove-padding-bottom-global-width {
      padding-top: 0; } }

.remove-padding-left {
  padding-left: 0; }
  .remove-padding-left-override {
    padding-left: 0 !important; }
  @media screen and (max-width: 39.9375em) {
    .remove-padding-left-for-small {
      padding-left: 0; }
    .remove-padding-left-for-small-override {
      padding-left: 0 !important; } }
  @media print, screen and (min-width: 40em) {
    .remove-padding-left-for-medium {
      padding-left: 0; }
    .remove-padding-left-for-medium-override {
      padding-left: 0 !important; } }
  @media print, screen and (min-width: 64em) {
    .remove-padding-left-for-large {
      padding-left: 0; }
    .remove-padding-left-for-large-override {
      padding-left: 0 !important; } }
  @media screen and (min-width: 75em) {
    .remove-padding-left-global-width {
      padding-top: 0; } }

.padding-fifteen {
  padding-top: 15px; }

.display-block {
  display: block; }
  .display-block-override {
    display: block !important; }
  @media screen and (max-width: 39.9375em) {
    .display-block-for-small {
      display: block; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .display-block-for-medium {
      display: block; } }
  @media print, screen and (min-width: 64em) {
    .display-block-for-large {
      display: block; } }

.display-inline-block {
  display: inline-block; }
  .display-inline-block-override {
    display: inline-block !important; }
  @media screen and (max-width: 39.9375em) {
    .display-inline-block-for-small {
      display: inline-block; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .display-inline-block-for-medium {
      display: inline-block; } }
  @media print, screen and (min-width: 64em) {
    .display-inline-block-for-large {
      display: inline-block; } }

.display-inline {
  display: inline; }
  .display-inline-override {
    display: inline !important; }
  @media screen and (max-width: 39.9375em) {
    .display-inline-for-small {
      display: inline; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .display-inline-for-medium {
      display: inline; } }
  @media print, screen and (min-width: 64em) {
    .display-inline-for-large {
      display: inline; } }

.no-mobile-display {
  display: block; }
  @media screen and (max-width: 39.9375em) {
    .no-mobile-display {
      display: none; } }

.mobile-display {
  display: none; }
  @media screen and (max-width: 39.9375em) {
    .mobile-display {
      display: block; } }

.position-relative {
  position: relative; }

.position-fixed {
  position: fixed; }

.position-absolute {
  position: absolute; }

.move-to-top {
  top: 0; }

.move-to-bottom {
  bottom: 0; }

.move-to-bottom-sixty {
  bottom: 60px; }

.move-to-left {
  left: 0; }

.move-to-right {
  right: 0; }

.full-absolute {
  /* @include full-absolute;*/ }

.vertical-align-middle {
  top: 50%;
  transform: translateY(-50%); }

.horizontal-align-middle {
  left: 50%;
  transform: translateX(-50%); }

.float-left {
  float: left; }

.float-right {
  float: right; }

@media print, screen and (min-width: 40em) {
  .home_line_two {
    margin-left: 20rem !important; } }

#flowers-bottom-right {
  text-align: right;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0; }
  @media screen and (max-width: 39.9375em) {
    #flowers-bottom-right {
      display: none; } }

#mc_embed_signup {
  margin: auto !important; }

#mc_embed_signup .clear {
  text-align: center !important; }

#mc_embed_signup h2 {
  text-align: center !important; }

#mc_embed_signup .button {
  border-radius: 0 !important; }

#mc_embed_signup #mce-success-response {
  color: #fff !important; }

#mc_embed_signup div.response {
  margin: 0 !important;
  padding: 0 0 10px 0 !important;
  width: 100% !important;
  text-align: center !important; }

#mc_embed_signup .mc-field-group {
  padding-bottom: 0 !important; }

#home_tour {
  position: fixed;
  z-index: 10;
  bottom: 0px;
  left: 0;
  width: 100%;
  color: #fff; }
  #home_tour .tour {
    background: #000;
    background: rgba(0, 0, 0, 0.4);
    padding: 30px 0; }
  #home_tour .responsive {
    width: 70%;
    margin: auto; }
    @media print, screen and (min-width: 40em) {
      #home_tour .responsive {
        width: 90%; } }
  #home_tour h3 {
    margin-bottom: 0;
    font-weight: 800; }
  #home_tour h4 {
    color: #000 !important; }
  #home_tour span {
    color: #fff !important; }
  #home_tour a:hover {
    opacity: 0.6; }

.orbit-container {
  height: 100vh !important; }

/* Set to height of slides */
.orbit-container .orbit-slides-container > * {
  position: relative !important;
  margin-left: 0 !important;
  float: left !important;
  height: 100% !important; }

.orbit-slide {
  background-size: cover !important; }

.orbit-slide, .orbit-figure {
  height: 100vh; }

.tag_line {
  z-index: 2;
  text-align: left;
  top: 53%;
  left: 3%;
  position: absolute; }
  .tag_line h1 {
    font-size: 3.2rem !important;
    /*color: $primary-color;*/
    font-weight: 600;
    line-height: 0.8; }

.title {
  font-weight: 800;
  color: transparent;
  font-size: 120px;
  background: url("http://104.152.168.27/~fairytale/wp-content/themes/Porter/dist/assets/images/textmask.jpg") repeat;
  background-position: 40% 50%;
  -webkit-background-clip: text;
  position: relative; }

.featured-placement {
  font-weight: 700;
  font-size: 36px; }
  @media screen and (max-width: 39.9375em) {
    .featured-placement {
      font-size: 16px; } }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: left;
  Z-INDEX: 1;
  bottom: -30px;
  LEFT: 2%;
  WIDTH: 90%; }

.orbit-bullets button.is-active {
  background-color: #fff;
  -webkit-box-shadow: 0 0 3px #000;
  -moz-box-shadow: 0 0 3px #000;
  box-shadow: 0 0 3px #000; }

.orbit-caption {
  width: 90%;
  left: 3%;
  padding: 1rem;
  background-color: transparent;
  height: 35%; }
  @media screen and (max-width: 39.9375em) {
    .orbit-caption {
      left: 8%;
      width: 80%;
      height: 60%; } }
  .orbit-caption h1 {
    line-height: 1;
    color: #fff;
    font-size: 2.9rem;
    font-weight: 600;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }
  @media screen and (max-width: 39.9375em) {
    .orbit-caption h1 {
      font-size: 1.9rem; } }
  .orbit-caption .button {
    padding: 0.85em 1.9em;
    border: 3px solid #000; }
  .orbit-caption .button:hover {
    border: 3px solid #fff; }

.orbit-bullets button {
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.2rem;
  border-radius: 50%;
  background-color: #999; }

@media all and (min-width: 1024px) and (max-width: 1280px) {
  .orbit-slide, html {
    background-size: 1280px 853px !important;
    -webkit-background-size: 1280px 853px !important;
    -moz-background-size: 1280px 853px !important;
    -o-background-size: 1280px 853px !important;
    background-attachment: scroll !important;
    background-position: top center !important; } }

@media all and (min-width: 768px) and (max-width: 1024px) {
  .orbit-slide, html {
    background-size: 1200px 800px !important;
    -webkit-background-size: 1200px 800px !important;
    -moz-background-size: 1200px 800px !important;
    -o-background-size: 1200px 800px !important;
    background-attachment: scroll !important;
    background-position: top center !important; } }

@media all and (min-width: 480px) and (max-width: 768px) {
  .orbit-slide, html {
    background-size: 1200px 800px !important;
    -webkit-background-size: 1200px 800px !important;
    -moz-background-size: 1200px 800px !important;
    -o-background-size: 1200px 800px !important;
    background-attachment: scroll !important;
    background-position: top center !important; } }

@media screen and (max-width: 39.9375em) {
  .orbit-slide, html {
    background-size: 1200px 800px !important;
    -webkit-background-size: 1200px 800px !important;
    -moz-background-size: 1200px 800px !important;
    -o-background-size: 1200px 800px !important;
    background-attachment: scroll !important;
    background-position: top center !important; } }

.tickets {
  font-size: 80%;
  font-weight: bold;
  color: #fff;
  background: #000;
  border: 1px solid #000;
  text-decoration: none;
  padding: 10px;
  transition: all 0.4s ease-in-out; }

.tickets:hover {
  background: #000;
  border: 1px solid #000; }

#tourdates, #tourdates tr, #tourdates td, #tourdates tbody {
  background: none;
  background-color: transparent; }

#tourdates tbody {
  border: 0; }

.memo {
  display: block; }
  @media screen and (max-width: 39.9375em) {
    .memo {
      display: none; } }
