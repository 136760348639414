// Background Properties

.background-color-primary {
    background-color: get-color('primary');
}

.background-color-secondary {
    background-color: get-color('secondary');
}

.background-color-light-gray {
    background-color: $light-gray;
}

.background-remove-color {
    filter: grayscale(1);
}

.background-color-white {
    background-color: $white;
}

.background-color-black {
    background-color: $black;
}


.background-cover {
    background-position: center center;
    background-size: cover;

    // X Position
    &.position-left {
        background-position-x: left;
    }

    &.position-right {
        background-position-x: right;
    }

    // Y Position
    &.position-top {
        background-position-y: top;
    }

    &.position-bottom {
        background-position-y: bottom;
    }
}