// Margin Helpers
.margin-auto {
  margin:auto;
}
// Add Margin
.margin-top-tiny {
   margin-top: rem-calc(10);
   &-override { margin-top: rem-calc(10) !important; }
}
.margin-top-small {
   margin-top: rem-calc(15);
   &-override { margin-top: rem-calc(15) !important; }
}
.margin-top-medium {
   margin-top: rem-calc(20);
   &-override { margin-top: rem-calc(20) !important; }
}
.margin-top-large {
   margin-top: rem-calc(25);
   &-override { margin-top: rem-calc(25) !important; }
}
.margin-top-xlarge {
   margin-top: rem-calc(30);
   &-override { margin-top: rem-calc(30) !important; }
}

.margin-bottom-tiny {
   margin-bottom: rem-calc(10);
   &-override { margin-bottom: rem-calc(10) !important; }
}
.margin-bottom-small {
   margin-bottom: rem-calc(15);
   &-override { margin-bottom: rem-calc(15) !important; }
}
.margin-bottom-medium {
   margin-bottom: rem-calc(20);
   &-override { margin-bottom: rem-calc(20) !important; }
}
.margin-bottom-large {
   margin-bottom: rem-calc(25);
   &-override { margin-bottom: rem-calc(25) !important; }
}
.margin-bottom-xlarge {
   margin-bottom: rem-calc(30);
   &-override { margin-bottom: rem-calc(30) !important; }
}

// Remove Margin
.remove-last-paragraph-margin-bottom {
    & > p:last-of-type {
        margin-bottom: 0 !important;
    }
}

.remove-margin {
    margin: 0;
    &-override { margin: 0 !important; }
    @include breakpoint(small only) {
        &-for-small { margin: 0; }
    }
    @include breakpoint(medium only) {
        &-for-medium { margin: 0; }
    }
    @include breakpoint(large) {
        &-for-large { margin: 0; }
    }
}

.remove-margin-top {
    margin-top: 0;
    &-override { margin-top: 0 !important; }
    @include breakpoint(small only) {
        &-for-small { margin-top: 0; }
    }
    @include breakpoint(medium only) {
        &-for-medium { margin-top: 0; }
    }
    @include breakpoint(large) {
        &-for-large { margin-top: 0; }
    }
}

.remove-margin-right {
    margin-right: 0;
    &-override { margin-right: 0 !important; }
    @include breakpoint(small only) {
        &-for-small { margin-right: 0; }
    }
    @include breakpoint(medium only) {
        &-for-medium { margin-right: 0; }
    }
    @include breakpoint(large) {
        &-for-large { margin-right: 0; }
    }
}

.remove-margin-bottom {
    margin-bottom: 0;
    &-override { margin-bottom: 0 !important; }
    @include breakpoint(small only) {
        &-for-small { margin-bottom: 0; }
    }
    @include breakpoint(medium only) {
        &-for-medium { margin-bottom: 0; }
    }
    @include breakpoint(large) {
        &-for-large { margin-bottom: 0; }
    }
}

.remove-margin-left {
    margin-left: 0;
    &-override { margin-left: 0 !important; }
    @include breakpoint(small only) {
        &-for-small { margin-left: 0; }
        &-for-small-override { margin-left: 0 !important; }
    }
    @include breakpoint(medium only) {
        &-for-medium { margin-left: 0; }
        &-for-medium-override { margin-left: 0 !important; }
    }
    @include breakpoint(large) {
        &-for-large { margin-left: 0; }
        &-for-large-override { margin-left: 0 !important; }
    }
}

.margin-minus-five {
  margin-top:-5px;
}