.full-width {
    width: 100%;

    &-override {
        width: 100% !important;
        max-width: 100% !important;
    }
}

.ninety-width {
    width: 90%;

    &-override {
        width: 90% !important;
        max-width: 90% !important;
    }
}

.eighty-width {
    width: 80%;

    &-override {
        width: 80% !important;
        max-width: 80% !important;
    }
}

.seventy-width {
    width: 70%;

    &-override {
        width: 70% !important;
        max-width: 70% !important;
    }
}

.sixty-width {
    width: 60%;

    &-override {
        width: 60% !important;
        max-width: 60% !important;
    }
}

.forty-width {
    width: 40%;

    &-override {
        width: 40% !important;
        max-width: 40% !important;
    }
}

/* pixels */
.three-hudnred-pixels {
    width: 300px;

    &-override {
        width: 300px !important;
        max-width: 300px !important;
    }
}

.two-hudnred-pixels {
    width: 200px;

    &-override {
        width: 200px !important;
        max-width: 200px !important;
    }
}

.one-sixty-pixels {
    width: 160px;

    &-override {
        width: 160px !important;
        max-width: 160px !important;
    }
}

.one-fifty-pixels {
    width: 150px;

    &-override {
        width: 150px !important;
        max-width: 150px !important;
    }
}

.one-thirty-pixels {
    width: 130px;

    &-override {
        width: 130px !important;
        max-width: 130px !important;
    }
}

.sixty-pixels {
    width: 60px;

    &-override {
        width: 60px !important;
        max-width: 60px !important;
    }
}

.forty-pixels {
    width: 40px;

    &-override {
        width: 40px !important;
        max-width: 40px !important;
    }
}

.forty-pixels-square {
    width: 40px;
    height: 40px;

    &-override {
        width: 40px !important;
        max-width: 40px !important;
    }
}

.thirty-pixels {
    width: 30px;

    &-override {
        width: 30px !important;
        max-width: 30px !important;
    }
}

.twenty-pixels {
    width: 20px;

    &-override {
        width: 20px !important;
        max-width: 20px !important;
    }
}

.ten-pixels {
    width: 10px;

    &-override {
        width: 10px !important;
        max-width: 10px !important;
    }
}