.OL {
    outline: 1px solid salmon;
}

.animation-delay-zero-three {
	-webkit-animation-delay: 0.3s;
}

.animation-delay-zero-five {
	-webkit-animation-delay: 0.5s;
}

.animation-delay-zero-seven {
	-webkit-animation-delay: 0.7s;
}

.animation-delay-zero-nine {
	-webkit-animation-delay: 0.9s;
}

.animation-duration-two {
	-webkit-animation-duration: 2s;
}


.round-image {
	border-radius: 50%;
}

.border-alternative {
	border: 3px solid $primary-color;
	transition:all 0.2s ease;
	
	&:hover {
		border:3px solid $white;
	}
}

.border-white {
	border: 10px solid $white;
	transition:all 0.2s ease;
	
	&:hover {
		border:10px solid $primary-color;
	}
}

.btn-border-clear {
	border:10px solid $white;
	&:hover {
		border:10px solid $alternative-color;
	}
}



.border-black {
	border: 1px solid $black;
}

.box-shadow {
	box-shadow: 0 12px 24px 0 rgba(137,151,199,0.45);
}

.border-radius-50 {
	border-radius:50px;
}

.image {
   position: relative;
   width: 100%; /* for IE 6 */
	 background: #673ab7;
		}

.image img:hover {
	opacity: 0.3;
}

.property-home img:hover {
  -webkit-filter: blur(5px);

}

.outer-glow {
	-webkit-box-shadow: -7px 2px 29px 0px rgba(0,0,0,0.35);
-moz-box-shadow: -7px 2px 29px 0px rgba(0,0,0,0.35);
box-shadow: -7px 2px 29px 0px rgba(0,0,0,0.35);
}

.learn-more button {
  letter-spacing: 1px;
  background: none;
  color: $black;
  position: relative;
  outline: none;
  border: none;
  height: 50px;
  width: 130px;
  font-size: 14px;
  z-index: 2;
  transition: .01s .23s ease-out all;
  overflow: hidden;
  text-align: left;
  padding-left: 15px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 80%;
    background: $primary-color;
    z-index: -1;
    transition: .3s ease-in all;
  }
  &:after {
    content: '';
    position: absolute;
    left: -5%;
    top: 5%;
    height: 90%;
    width: 5%;
    background: white;
    z-index: -1;
    transition: .4s .02s ease-in all;
  }
  &:hover {
    cursor: pointer;
    color: transparent;
    &:before {
      left: 100%;
      width: 25%;
    }
    &:after {
      left: 100%;
      width: 70%;
    }
    .icon-right.after:after {
      left: -80px;
      color: $primary-color;
      transition: .2s .2s ease all;
    }
    .icon-right.after:before {
      left: -104px;
      top: 14px;
      opacity: 0.4;
      color: $primary-color;
    }
  }
}

.icon-right {
  position: absolute;
  top: 0;
  right: 0;
  &:after {
    font-family: "FontAwesome";
    content: '\2192';
    font-size: 24px;
    display: inline-block;
    position: relative;
    top: 26px;
    transform: translate3D(0, -50%, 0);
  }
  &.after:after {
    left: -250px;
    color: black;
    transition: .15s .25s ease left, .5s .05s ease color;
  }
  &.after:before {
    content: 'Learn More';
    position: absolute;
    left: -230px;
    top: 14px;
    opacity: 0;
    transition: .2s ease-in all;
  }
}



/*
.property-home h2, h3 {
   position: absolute;
   bottom: 0px;
   left: 3%;
   width: 97%;
}*/