.sections {
    min-height: 100vh;
    overflow:visible;

    &-90 {
      min-height: 90vh;
      overflow:visible;
    }

    &-80 {
      min-height: 80vh;
      overflow:visible;
    }
    &-60 {
      min-height: 60vh;
      overflow:visible;
    }
}

.page-id-32 #scamp_player, .page-id-34 #scamp_player {
  display: none !important;
}

.thumb-artist {
    position: relative;
    width: 100%;
    height: 65vh;
    float: left;
    margin-top: 0;
    margin-bottom: 20px;

    max-height: 550px;
    overflow: hidden;
}

.main-wrap {
  @include xy-grid-container;
  @include xy-grid();
  margin-top: 1rem;

  @include breakpoint(medium) {
    margin-top: 3rem;
  }

  &.sidebar-left {

    // Place the sidebar below main content on small screens ...
      @include breakpoint(small) {
        .main-content { order: 1; }
        .sidebar { order: 2; }
      }

    // ... and to the left on medium-up screens, when using the sidebar-left template
      @include breakpoint(medium) {
        .main-content { order: 2; }
        .sidebar { order: 1; }
      }
  }

 

  // Full width template
  &.full-width {
    .main-content {
      @include xy-cell(12);
    }
  }

 

  .main-content {

    // Default template
    @include breakpoint(small) {
      @include xy-cell(12);
    }

    @include breakpoint(medium) {
      @include xy-cell(8);
    }

  }

  .sidebar {

    @include breakpoint(small) {
      @include xy-cell(12);
    }

    @include breakpoint(medium) {
      @include xy-cell(4);
      margin-top: 1rem;
    }

  }
}


label {

    color: $dark-gray;
}

[type='password'] {
  max-width: 300px;
    margin: auto;
}

[type='submit'], [type='button'] {
    background: $primary-color;
    color: $white;
    padding: 10px 40px;
    margin-top: 20px;
}
  



.thumb {
   position: relative;
   width: 100%;
   height: auto;
   margin: 0;
   float: left;
   overflow: hidden;
   background: $primary-color;
}

@media (max-width: 767px) {
  .thumb {
     position: relative;
     width: 100%;
     height: auto;
     margin: 0;
     float: none;
     overflow: hidden;
     display: block;
  text-align: center;
  }

  .lineup_decorations {
    display: none;
  }

  .days_header h2 {
    margin:0;
    padding:10px 0
  }
}


.text {
   display: block;
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   font-weight: 800;
   text-transform: uppercase;
   padding-top:10px;
   padding-bottom:10px;
       text-align: center;
       color: #fff;
}

.text img {
    height: 100px;
    margin-top: 10%;
    display: table-cell;
    margin: auto;
    vertical-align: middle;
    margin-top: 15%;
}
.thumb:hover .yellow2 {
   display: block;
   background: $primary-color;
}

.thumb:hover .border-white {
  opacity: 0.7;
}

.thumb:hover .orange2 {
   display: block;
   background: $primary-color;
}
.thumb a, .thumb a:visited {
  color:#fff;
  text-decoration: none;
  font-size:19px;
}

.thumb a:hover {
  color:#fff !important
}


.bit-widget .bit-nav-bar-container .bit-nav-bar {
    display: none !important
}
.bit-widget {
    background-color: transparent !important;
}

.bit-widget.bit-layout-row .bit-event .bit-details .bit-date {
  white-space: normal !important;
  text-align:center;
    flex: none;
    border: 2px solid $primary-color;
    width: 70px;
    height: 70px;
    padding: 20px;
    font-size: 20px;
    margin-right: 20px;
    background: $white;
   
}

.bit-widget.bit-layout-row .bit-event .bit-details .bit-venue {
    text-align: left;
}

@include  breakpoint(small only) {
  .bit-widget .bit-date {
    font-weight: bold;
    width: 100%;
    text-align: left;
    font-size: 30px;
    margin-bottom:10px;
  }

  .bit-events-container {
    text-align:left;
  }
}


.gform_wrapper form {

    max-width: 600px !important;

}

.gform_wrapper textarea, .gform_wrapper  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    font-size: inherit;
    font-family: inherit;
    padding: 5px 4px;
    letter-spacing: normal;
    width: 100%;
    background: $white;
    color: $black;
    border: 1px solid $primary-color;
}






/**** IMAGES 88/

.grid {
  position: relative;
  clear: both;
  margin: 0 auto;
  padding: 1em 0 4em;
  max-width: 1000px;
  list-style: none;
  text-align: center;
}

/* Common style */
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 0;
  min-width: 120px;
  max-width: 880px;
  max-height: 460px;
  width: 100%;
  height: auto;
  background: $black;
  text-align: center;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  width:100%;
  opacity: 1;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 800;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}



/* Individual effects */

/*---------------*/
/***** Lily *****/
/*---------------*/

figure.effect-lily img {
  max-width: none;
  width: -webkit-calc(100% + 50px);
  width: calc(100% + 50px);
  opacity: 0.7;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-40px,0, 0);
  transform: translate3d(-40px,0,0);
}

figure.effect-lily figcaption {
  text-align: left;
}

figure.effect-lily figcaption > div {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  height: 50%;
}

figure.effect-lily h2,
figure.effect-lily p {
  -webkit-transform: translate3d(0,40px,0);
  transform: translate3d(0,40px,0);
}

figure.effect-lily h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-lily p {
  color: rgba(255,255,255,0.8);
  opacity: 0;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
  transition: opacity 0.2s, transform 0.35s;
}

figure.effect-lily:hover img,
figure.effect-lily:hover p {
  opacity: 1;
}

figure.effect-lily:hover img,
figure.effect-lily:hover h2,
figure.effect-lily:hover p {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-lily:hover p {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
}

/*---------------*/
/***** Sadie *****/
/*---------------*/

figure.effect-sadie figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(top, rgba(72,76,97,0) 0%, rgba(72,76,97,0.8) 75%);
  background: linear-gradient(to bottom, rgba(72,76,97,0) 0%, rgba(72,76,97,0.8) 75%);
  content: '';
  opacity: 0;
  -webkit-transform: translate3d(0,50%,0);
  transform: translate3d(0,50%,0);
}

figure.effect-sadie h2 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #484c61;
  -webkit-transition: -webkit-transform 0.35s, color 0.35s;
  transition: transform 0.35s, color 0.35s;
  -webkit-transform: translate3d(0,-50%,0);
  transform: translate3d(0,-50%,0);
}

figure.effect-sadie figcaption::before,
figure.effect-sadie p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-sadie p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  opacity: 0;
  -webkit-transform: translate3d(0,10px,0);
  transform: translate3d(0,10px,0);
}

figure.effect-sadie:hover h2 {
  color: #fff;
  -webkit-transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
  transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
}

figure.effect-sadie:hover figcaption::before ,
figure.effect-sadie:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*---------------*/
/***** Roxy *****/
/*---------------*/

figure.effect-roxy {
  background: -webkit-linear-gradient(45deg, #ff89e9 0%, #05abe0 100%);
  background: linear-gradient(45deg, #ff89e9 0%,#05abe0 100%);
}

figure.effect-roxy img {
  max-width: none;
  width: -webkit-calc(100% + 60px);
  width: calc(100% + 60px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50px,0,0);
  transform: translate3d(-50px,0,0);
}

figure.effect-roxy figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-20px,0,0);
  transform: translate3d(-20px,0,0);
}

figure.effect-roxy figcaption {
  padding: 3em;
  text-align: left;
}

figure.effect-roxy h2 {
  padding: 30% 0 10px 0;
}

figure.effect-roxy p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(-10px,0,0);
}

figure.effect-roxy:hover img {
  opacity: 0.7;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-roxy:hover figcaption::before,
figure.effect-roxy:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*---------------*/
/***** Bubba *****/
/*---------------*/

figure.effect-bubba {
  background: #9e5406;
}

figure.effect-bubba img {
  opacity: 0.7;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-bubba:hover img {
  opacity: 0.4;
}

figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-bubba figcaption::before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0,1);
  transform: scale(0,1);
}

figure.effect-bubba figcaption::after {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1,0);
  transform: scale(1,0);
}

figure.effect-bubba h2 {
  padding-top: 30%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,-20px,0);
  transform: translate3d(0,-20px,0);
}

figure.effect-bubba p {
  padding: 20px 2.5em;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,20px,0);
  transform: translate3d(0,20px,0);
}

figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-bubba:hover h2,
figure.effect-bubba:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*---------------*/
/***** Romeo *****/
/*---------------*/

figure.effect-romeo {
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

figure.effect-romeo img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,0,300px);
  transform: translate3d(0,0,300px);
}

figure.effect-romeo:hover img {
  opacity: 0.6;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-romeo figcaption::before,
figure.effect-romeo figcaption::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 1px;
  background: #fff;
  content: '';
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%,-50%,0);
  transform: translate3d(-50%,-50%,0);
}

figure.effect-romeo:hover figcaption::before {
  opacity: 0.5;
  -webkit-transform: translate3d(-50%,-50%,0) rotate(45deg);
  transform: translate3d(-50%,-50%,0) rotate(45deg);
}

figure.effect-romeo:hover figcaption::after {
  opacity: 0.5;
  -webkit-transform: translate3d(-50%,-50%,0) rotate(-45deg);
  transform: translate3d(-50%,-50%,0) rotate(-45deg);
}

figure.effect-romeo h2,
figure.effect-romeo p {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-romeo h2 {
  -webkit-transform: translate3d(0,-50%,0) translate3d(0,-150%,0);
  transform: translate3d(0,-50%,0) translate3d(0,-150%,0);
}

figure.effect-romeo p {
  padding: 0.25em 2em;
  -webkit-transform: translate3d(0,-50%,0) translate3d(0,150%,0);
  transform: translate3d(0,-50%,0) translate3d(0,150%,0);
}

figure.effect-romeo:hover h2 {
  -webkit-transform: translate3d(0,-50%,0) translate3d(0,-100%,0);
  transform: translate3d(0,-50%,0) translate3d(0,-100%,0);
}

figure.effect-romeo:hover p {
  -webkit-transform: translate3d(0,-50%,0) translate3d(0,100%,0);
  transform: translate3d(0,-50%,0) translate3d(0,100%,0);
}

/*---------------*/
/***** Layla *****/
/*---------------*/

figure.effect-layla {
  background: #18a367;
}

figure.effect-layla img {
  height: 390px;
}

figure.effect-layla figcaption {
  padding: 3em;
}

figure.effect-layla figcaption::before,
figure.effect-layla figcaption::after {
  position: absolute;
  content: '';
  opacity: 0;
}

figure.effect-layla figcaption::before {
  top: 50px;
  right: 30px;
  bottom: 50px;
  left: 30px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0,1);
  transform: scale(0,1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

figure.effect-layla figcaption::after {
  top: 30px;
  right: 50px;
  bottom: 30px;
  left: 50px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1,0);
  transform: scale(1,0);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

figure.effect-layla h2 {
  padding-top: 26%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-layla p {
  padding: 0.5em 2em;
  text-transform: none;
  opacity: 0;
  -webkit-transform: translate3d(0,-10px,0);
  transform: translate3d(0,-10px,0);
}

figure.effect-layla img,
figure.effect-layla h2 {
  -webkit-transform: translate3d(0,-30px,0);
  transform: translate3d(0,-30px,0);
}

figure.effect-layla img,
figure.effect-layla figcaption::before,
figure.effect-layla figcaption::after,
figure.effect-layla p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-layla:hover img {
  opacity: 0.7;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-layla:hover figcaption::before,
figure.effect-layla:hover figcaption::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-layla:hover h2,
figure.effect-layla:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-layla:hover figcaption::after,
figure.effect-layla:hover h2,
figure.effect-layla:hover p,
figure.effect-layla:hover img {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

/*---------------*/
/***** Honey *****/
/*---------------*/

figure.effect-honey {
  background: #4a3753;
}

figure.effect-honey img {
  opacity: 0.9;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-honey:hover img {
  opacity: 0.5;
}

figure.effect-honey figcaption::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: #fff;
  content: '';
  -webkit-transform: translate3d(0,10px,0);
  transform: translate3d(0,10px,0);
}

figure.effect-honey h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em 1.5em;
  width: 100%;
  text-align: left;
  -webkit-transform: translate3d(0,-30px,0);
  transform: translate3d(0,-30px,0);
}

figure.effect-honey h2 i {
  font-style: normal;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,-30px,0);
  transform: translate3d(0,-30px,0);
}

figure.effect-honey figcaption::before,
figure.effect-honey h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-honey:hover figcaption::before,
figure.effect-honey:hover h2,
figure.effect-honey:hover h2 i {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*---------------*/
/***** Oscar *****/
/*---------------*/

figure.effect-oscar {
  background: -webkit-linear-gradient(45deg, #22682a 0%, #9b4a1b 40%, #3a342a 100%);
  background: linear-gradient(45deg, #22682a 0%,#9b4a1b 40%,#3a342a 100%);
}

figure.effect-oscar img {
  opacity: 0.9;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-oscar figcaption {
  padding: 3em;
  background-color: rgba(58,52,42,0.7);
  -webkit-transition: background-color 0.35s;
  transition: background-color 0.35s;
}

figure.effect-oscar figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: '';
}

figure.effect-oscar h2 {
  margin: 20% 0 10px 0;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0);
}

figure.effect-oscar figcaption::before,
figure.effect-oscar p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0);
  transform: scale(0);
}

figure.effect-oscar:hover h2 {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-oscar:hover figcaption::before,
figure.effect-oscar:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-oscar:hover figcaption {
  background-color: rgba(58,52,42,0);
}

figure.effect-oscar:hover img {
  opacity: 0.4;
}

/*---------------*/
/***** Marley *****/
/*---------------*/

figure.effect-marley figcaption {
  text-align: right;
}

figure.effect-marley h2,
figure.effect-marley p {
  position: absolute;
  right: 30px;
  left: 30px;
  padding: 10px 0;
}


figure.effect-marley p {
  bottom: 30px;
  line-height: 1.5;
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0);
}

figure.effect-marley h2 {
  top: 30px;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,20px,0);
  transform: translate3d(0,20px,0);
}

figure.effect-marley:hover h2 {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-marley h2::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  background: #fff;
  content: '';
  -webkit-transform: translate3d(0,40px,0);
  transform: translate3d(0,40px,0);
}

figure.effect-marley h2::after,
figure.effect-marley p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-marley:hover h2::after,
figure.effect-marley:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*---------------*/
/***** Ruby *****/
/*---------------*/

figure.effect-ruby {
  background-color: #17819c;
}

figure.effect-ruby img {
  opacity: 0.7;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

figure.effect-ruby:hover img {
  opacity: 0.5;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-ruby h2 {
  margin-top: 20%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,20px,0);
  transform: translate3d(0,20px,0);
}

figure.effect-ruby p {
  margin: 1em 0 0;
  padding: 3em;
  border: 1px solid #fff;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,20px,0) scale(1.1);
  transform: translate3d(0,20px,0) scale(1.1);
} 

figure.effect-ruby:hover h2 {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-ruby:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0) scale(1);
  transform: translate3d(0,0,0) scale(1);
}

/*---------------*/
/***** Milo *****/
/*---------------*/

figure.effect-milo {
  background: #2e5d5a;
}

figure.effect-milo img {
  max-width: none;
  width: -webkit-calc(100% + 60px);
  width: calc(100% + 60px);
  opacity: 1;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-30px,0,0) scale(1.12);
  transform: translate3d(-30px,0,0) scale(1.12);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-milo:hover img {
  opacity: 0.5;
  -webkit-transform: translate3d(0,0,0) scale(1);
  transform: translate3d(0,0,0) scale(1);
}

figure.effect-milo h2 {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1em 1.2em;
}

figure.effect-milo p {
  padding: 0 10px 0 0;
  width: 50%;
  border-right: 1px solid #fff;
  text-align: right;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-40px,0,0);
  transform: translate3d(-40px,0,0);
}

figure.effect-milo:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*---------------*/
/***** Dexter *****/
/*---------------*/

figure.effect-dexter {
  background: -webkit-linear-gradient(top, rgba(37,141,200,1) 0%, rgba(104,60,19,1) 100%);
  background: linear-gradient(to bottom, rgba(37,141,200,1) 0%,rgba(104,60,19,1) 100%); 
}

figure.effect-dexter img {
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-dexter:hover img {
  opacity: 0.4;
}

figure.effect-dexter figcaption::after {
  position: absolute;
  right: 30px;
  bottom: 30px;
  left: 30px;
  height: -webkit-calc(50% - 30px);
  height: calc(50% - 30px);
  border: 7px solid #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,-100%,0);
  transform: translate3d(0,-100%,0);
}

figure.effect-dexter:hover figcaption::after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-dexter figcaption {
  padding: 3em;
  text-align: left;
}

figure.effect-dexter p {
  position: absolute;
  right: 60px;
  bottom: 60px;
  left: 60px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,-100px,0);
  transform: translate3d(0,-100px,0);
}

figure.effect-dexter:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*---------------*/
/***** Sarah *****/
/*---------------*/

figure.effect-sarah {
  background: #42b078;
}

figure.effect-sarah img {
  max-width: none;
  width: -webkit-calc(100% + 20px);
  width: calc(100% + 20px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(-10px,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-sarah:hover img {
  opacity: 0.4;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-sarah figcaption {
  text-align: left;
}

figure.effect-sarah h2 {
  position: relative;
  overflow: hidden;
  padding: 0.5em 0;
}

figure.effect-sarah h2::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
}

figure.effect-sarah:hover h2::after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-sarah p {
  padding: 1em 0;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}

figure.effect-sarah:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*---------------*/
/***** Zoe *****/
/*---------------*/

figure.effect-zoe figcaption {
  top: auto;
  bottom: 0;
  padding: 1em;
  height: 3.75em;
  background: #fff;
  color: #3c4a50;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0);
}

figure.effect-zoe h2 {
  float: left;
}

figure.effect-zoe p.icon-links a {
  float: right;
  color: #3c4a50;
  font-size: 1.4em;
}

figure.effect-zoe:hover p.icon-links a:hover,
figure.effect-zoe:hover p.icon-links a:focus {
  color: #252d31;
}

figure.effect-zoe p.description {
  position: absolute;
  bottom: 8em;
  padding: 2em;
  color: #fff;
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  -webkit-backface-visibility: hidden; /* Fix for Chrome 37.0.2062.120 (Mac) */
}

figure.effect-zoe h2,
figure.effect-zoe p.icon-links a {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,200%,0);
  transform: translate3d(0,200%,0);
}

figure.effect-zoe p.icon-links a span::before {
  display: inline-block;
  padding: 8px 10px;
  font-family: 'feathericons';
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye::before {
  content: '\e000';
}

.icon-paper-clip::before {
  content: '\e001';
}

.icon-heart::before {
  content: '\e024';
}

figure.effect-zoe h2 {
  display: inline-block;
}

figure.effect-zoe:hover p.description {
  opacity: 1;
}

figure.effect-zoe:hover figcaption,
figure.effect-zoe:hover h2,
figure.effect-zoe:hover p.icon-links a {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-zoe:hover h2 {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-zoe:hover p.icon-links a:nth-child(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-zoe:hover p.icon-links a:nth-child(2) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

figure.effect-zoe:hover p.icon-links a:first-child {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

/*---------------*/
/***** Chico *****/
/*---------------*/

figure.effect-chico img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.12);
  transform: scale(1.12);
}

figure.effect-chico:hover img {
  opacity: 0.5;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-chico figcaption {
  padding: 3em;
}

figure.effect-chico figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: '';
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

figure.effect-chico figcaption::before,
figure.effect-chico p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-chico h2 {
  padding: 20% 0 20px 0;
}

figure.effect-chico p {
  margin: 0 auto;
  max-width: 200px;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

figure.effect-chico:hover figcaption::before,
figure.effect-chico:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


/*---------------*/
/***** Chico Altered *****/
/*---------------*/

figure.effect-chico-altered img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.12);
  transform: scale(1.12);
}

figure.effect-chico-altered:hover img {
  opacity: 0.2;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-chico-altered figcaption {
  padding: 3em;
}

figure.effect-chico-altered figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 0px solid #fff;
  content: '';
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

figure.effect-chico-altered figcaption::before,
figure.effect-chico-altered p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-chico-altered h2 {
  padding: 20% 0 20px 0;
}

figure.effect-chico-altered p {
  margin: 0 auto;
  max-width: 200px;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

figure.effect-chico-altered:hover figcaption::before,
figure.effect-chico-altered:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}







/* Individual effects */

/*---------------*/
/***** Julia *****/
/*---------------*/

figure.effect-julia {
  background: #2f3238;
}

figure.effect-julia img {
  max-width: none;
  height: 400px;
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, transform 1s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-julia figcaption {
  text-align: left;
}

figure.effect-julia h2 {
  position: relative;
  padding: 0.5em 0;
}

figure.effect-julia p {
  display: inline-block;
  margin: 0 0 0.25em;
  padding: 0.4em 1em;
  background: rgba(255,255,255,0.9);
  color: #2f3238;
  text-transform: none;
  font-weight: 500;
  font-size: 75%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-360px,0,0);
  transform: translate3d(-360px,0,0);
}

figure.effect-julia p:first-child {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

figure.effect-julia p:nth-of-type(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia p:nth-of-type(3) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:first-child {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

figure.effect-julia:hover p:nth-of-type(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:nth-of-type(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia:hover img {
  opacity: 0.4;
  -webkit-transform: scale3d(1.1,1.1,1);
  transform: scale3d(1.1,1.1,1);
}

figure.effect-julia:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*-----------------*/
/***** Goliath *****/
/*-----------------*/

figure.effect-goliath {
  background: $primary-color;
}

figure.effect-goliath img,
figure.effect-goliath h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-goliath img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-goliath h2,
figure.effect-goliath p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
}

figure.effect-goliath p {
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,50px,0);
  transform: translate3d(0,50px,0);
}

figure.effect-goliath:hover img {
  -webkit-transform: translate3d(0,-100px,0);
  transform: translate3d(0,-100px,0);
}

figure.effect-goliath:hover h2 {
  -webkit-transform: translate3d(0,-100px,0);
  transform: translate3d(0,-100px,0);
}

figure.effect-goliath:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*-----------------*/
/***** Hera *****/
/*-----------------*/

figure.effect-hera {
  background: #303fa9;
}

figure.effect-hera h2 {
  font-size: 158.75%;
}

figure.effect-hera h2,
figure.effect-hera p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%,-50%,0);
  transform: translate3d(-50%,-50%,0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
}

figure.effect-hera figcaption::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  border: 2px solid #fff;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(0,0,1);
  transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(0,0,1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
}

figure.effect-hera p {
  width: 100px;
  text-transform: none;
  font-size: 121%;
  line-height: 2;
}

figure.effect-hera p a {
  color: #fff;
}

figure.effect-hera p a:hover,
figure.effect-hera p a:focus {
  opacity: 0.6;
}

figure.effect-hera p a i {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-hera p a:first-child i {
  -webkit-transform: translate3d(-60px,-60px,0);
  transform: translate3d(-60px,-60px,0);
}

figure.effect-hera p a:nth-child(2) i {
  -webkit-transform: translate3d(60px,-60px,0);
  transform: translate3d(60px,-60px,0);
}

figure.effect-hera p a:nth-child(3) i {
  -webkit-transform: translate3d(-60px,60px,0);
  transform: translate3d(-60px,60px,0);
}

figure.effect-hera p a:nth-child(4) i {
  -webkit-transform: translate3d(60px,60px,0);
  transform: translate3d(60px,60px,0);
}

figure.effect-hera:hover figcaption::before {
  opacity: 1;
  -webkit-transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(1,1,1);
  transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(1,1,1);
}

figure.effect-hera:hover h2 {
  opacity: 0;
  -webkit-transform: translate3d(-50%,-50%,0) scale3d(0.8,0.8,1);
  transform: translate3d(-50%,-50%,0) scale3d(0.8,0.8,1);
}

figure.effect-hera:hover p i:empty {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0); /* just because it's stronger than nth-child */
  opacity: 1;
}

/*-----------------*/
/***** Winston *****/
/*-----------------*/

figure.effect-winston {
  background: #162633;
  text-align: left;
}

figure.effect-winston img {
  -webkit-transition: opacity 0.45s;
  transition: opacity 0.45s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-winston figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/triangle.svg) no-repeat center center;
  background-size: 100% 100%;
  content: '';
  -webkit-transition: opacity 0.45s, -webkit-transform 0.45s;
  transition: opacity 0.45s, transform 0.45s;
  -webkit-transform: rotate3d(0,0,1,45deg);
  transform: rotate3d(0,0,1,45deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

figure.effect-winston h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,20px,0);
  transform: translate3d(0,20px,0);
}

figure.effect-winston p {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 1.5em 7% 0;
}

figure.effect-winston a {
  margin: 0 10px;
  color: #5d504f;
  font-size: 170%;
}

figure.effect-winston a:hover,
figure.effect-winston a:focus {
  color: #cc6055;
}

figure.effect-winston p a i {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,50px,0);
  transform: translate3d(0,50px,0);
}

figure.effect-winston:hover img {
  opacity: 0.6;
}

figure.effect-winston:hover h2 {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-winston:hover figcaption::before {
  opacity: 0.7;
  -webkit-transform: rotate3d(0,0,1,20deg);
  transform: rotate3d(0,0,1,20deg);
}

figure.effect-winston:hover p i {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-winston:hover p a:nth-child(3) i {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-winston:hover p a:nth-child(2) i {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-winston:hover p a:first-child i {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

/*-----------------*/
/***** Selena *****/
/*-----------------*/

figure.effect-selena {
  background: #fff;
}

figure.effect-selena img {
  opacity: 0.95;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

figure.effect-selena:hover img {
  -webkit-transform: scale3d(0.95,0.95,1);
  transform: scale3d(0.95,0.95,1);
}

figure.effect-selena h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,20px,0);
  transform: translate3d(0,20px,0);
}

figure.effect-selena p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: perspective(1000px) rotate3d(1,0,0,90deg);
  transform: perspective(1000px) rotate3d(1,0,0,90deg);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

figure.effect-selena:hover h2 {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-selena:hover p {
  opacity: 1;
  -webkit-transform: perspective(1000px) rotate3d(1,0,0,0);
  transform: perspective(1000px) rotate3d(1,0,0,0);
}

/*-----------------*/
/***** Terry *****/
/*-----------------*/

figure.effect-terry {
  background: #34495e;
}

figure.effect-terry figcaption {
  padding: 1em;
}

figure.effect-terry figcaption::before,
figure.effect-terry figcaption::after {
  position: absolute;
  width: 200%;
  height: 200%;
  border-style: solid;
  border-color: #101010;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-terry figcaption::before {
  right: 0;
  bottom: 0;
  border-width: 0 70px 60px 0;
  -webkit-transform: translate3d(70px,60px,0);
  transform: translate3d(70px,60px,0);
}

figure.effect-terry figcaption::after {
  top: 0;
  left: 0;
  border-width: 15px 0 0 15px;
  -webkit-transform: translate3d(-15px,-15px,0);
  transform: translate3d(-15px,-15px,0);
}

figure.effect-terry img,
figure.effect-terry p a {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-terry img {
  opacity: 0.85;
}

figure.effect-terry h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.4em 10px;
  width: 50%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}

@media screen and (max-width: 920px) {
  figure.effect-terry h2 {
    padding: 0.75em 10px;
    font-size: 120%;
  }
}

figure.effect-terry p {
  float: right;
  clear: both;
  text-align: left;
  text-transform: none;
  font-size: 111%;
}

figure.effect-terry p a {
  display: block;
  margin-bottom: 1em;
  color: #fff;
  opacity: 0;
  -webkit-transform: translate3d(90px,0,0);
  transform: translate3d(90px,0,0);
}

figure.effect-terry p a:hover,
figure.effect-terry p a:focus {
  color: #f3cf3f;
}

figure.effect-terry:hover figcaption::before,
figure.effect-terry:hover figcaption::after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-terry:hover img {
  opacity: 0.6;

}

figure.effect-terry:hover h2,
figure.effect-terry:hover p a {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-terry:hover p a {
  opacity: 1;
}

figure.effect-terry:hover p a:first-child {
  -webkit-transition-delay: 0.025s;
  transition-delay: 0.025s;
}

figure.effect-terry:hover p a:nth-child(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-terry:hover p a:nth-child(3) {
  -webkit-transition-delay: 0.075s;
  transition-delay: 0.075s;
}

figure.effect-terry:hover p a:nth-child(4) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

/*-----------------*/
/***** Phoebe *****/
/*-----------------*/

figure.effect-phoebe {
  background: #675983;
}

figure.effect-phoebe img {
  opacity: 0.85;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-phoebe:hover img {
  opacity: 0.6;
}

figure.effect-phoebe figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/triangle2.svg) no-repeat center center;
  background-size: 100% 100%;
  content: '';
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(5,2.5,1);
  transform: scale3d(5,2.5,1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

figure.effect-phoebe:hover figcaption::before {
  opacity: 0.6;
  -webkit-transform: scale3d(1,1,1);
  transform: scale3d(1,1,1);
}

figure.effect-phoebe h2 {
  margin-top: 1em;
  -webkit-transition: transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,40px,0);
  transform: translate3d(0,40px,0);
}

figure.effect-phoebe:hover h2 {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-phoebe p a {
  color: #fff;
  font-size: 140%;
  opacity: 0;
  position: relative;
  display: inline-block;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-phoebe p a:first-child {
  -webkit-transform: translate3d(-60px,-60px,0);
  transform: translate3d(-60px,-60px,0);
}

figure.effect-phoebe p a:nth-child(2) {
  -webkit-transform: translate3d(0,60px,0);
  transform: translate3d(0,60px,0);
}

figure.effect-phoebe p a:nth-child(3) {
  -webkit-transform: translate3d(60px,-60px,0);
  transform: translate3d(60px,-60px,0);
}

figure.effect-phoebe:hover p a {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*-----------------*/
/***** Apollo *****/
/*-----------------*/

figure.effect-apollo {
  background: #3498db;
}

figure.effect-apollo img {
  opacity: 0.95;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.05,1.05,1);
  transform: scale3d(1.05,1.05,1);
}

figure.effect-apollo figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.5);
  content: '';
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
  transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
}

figure.effect-apollo p {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 3em;
  padding: 0 1em;
  max-width: 150px;
  border-right: 4px solid #fff;
  text-align: right;
  opacity: 0;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-apollo h2 {
  text-align: left;
}

figure.effect-apollo:hover img {
  opacity: 0.6;
  -webkit-transform: scale3d(1,1,1);
  transform: scale3d(1,1,1);
}

figure.effect-apollo:hover figcaption::before {
  -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
  transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
}

figure.effect-apollo:hover p {
  opacity: 1;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

/*-----------------*/
/***** Kira *****/
/*-----------------*/

figure.effect-kira {
  background: #fff;
  text-align: left;
}

figure.effect-kira img {
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-kira figcaption {
  z-index: 1;
}

figure.effect-kira p {
  padding: 2.25em 0.5em;
  font-weight: 600; 
  font-size: 100%;
  line-height: 1.5;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,-10px,0);
  transform: translate3d(0,-10px,0);
}

figure.effect-kira p a {
  margin: 0 0.5em;
  color: #101010;
}

figure.effect-kira p a:hover,
figure.effect-kira p a:focus {
  opacity: 0.6;
}

figure.effect-kira figcaption::before {
  position: absolute;
  top: 0;
  right: 2em;
  left: 2em;
  z-index: -1;
  height: 3.5em;
  background: #fff;
  content: '';
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,4em,0) scale3d(1,0.023,1) ;
  transform: translate3d(0,4em,0) scale3d(1,0.023,1);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

figure.effect-kira:hover img {
  opacity: 0.5;
}

figure.effect-kira:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-kira:hover figcaption::before {
  opacity: 0.7;
  -webkit-transform: translate3d(0,5em,0) scale3d(1,1,1) ;
  transform: translate3d(0,5em,0) scale3d(1,1,1);
}

/*-----------------*/
/***** Steve *****/
/*-----------------*/

figure.effect-steve {
  z-index: auto;
  overflow: visible;
  background: #000;
}

figure.effect-steve:before,
figure.effect-steve h2:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000;
  content: '';
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-steve:before {
  box-shadow: 0 3px 30px rgba(0,0,0,0.8);
  opacity: 0;
}

figure.effect-steve figcaption {
  z-index: 1;
}

figure.effect-steve img {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: perspective(1000px) translate3d(0,0,0);
  transform: perspective(1000px) translate3d(0,0,0);
}

figure.effect-steve h2,
figure.effect-steve p {
  background: #fff;
  color: #2d434e;
}

figure.effect-steve h2 {
  position: relative;
  margin-top: 2em;
  padding: 0.25em;
}

figure.effect-steve h2:before {
  box-shadow: 0 1px 10px rgba(0,0,0,0.5);
}

figure.effect-steve p {
  margin-top: 1em;
  padding: 0.5em;
  font-weight: 800;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(0.9,0.9,1);
  transform: scale3d(0.9,0.9,1);
}

figure.effect-steve:hover:before {
  opacity: 1;
}

figure.effect-steve:hover img {
  -webkit-transform: perspective(1000px) translate3d(0,0,21px);
  transform: perspective(1000px) translate3d(0,0,21px);
}

figure.effect-steve:hover h2:before {
  opacity: 0;
}

figure.effect-steve:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1,1,1);
  transform: scale3d(1,1,1);
}

/*-----------------*/
/***** Moses *****/
/*-----------------*/

figure.effect-moses {
  background: -webkit-linear-gradient(-45deg, #EC65B7 0%,#05E0D8 100%);
  background: linear-gradient(-45deg, #EC65B7 0%,#05E0D8 100%);
}

figure.effect-moses img {
  opacity: 0.85;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-moses h2,
figure.effect-moses p {
  padding: 20px;
  width: 50%;
  height: 50%;
  border: 2px solid #fff;
}

figure.effect-moses h2 {
  padding: 20px;
  width: 50%;
  height: 50%;
  text-align: left;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(10px,10px,0);
  transform: translate3d(10px,10px,0);
}

figure.effect-moses p {
  float: right;
  padding: 20px;
  text-align: right;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%,-50%,0);
  transform: translate3d(-50%,-50%,0);
}

figure.effect-moses:hover h2 {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-moses:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

figure.effect-moses:hover img {
  opacity: 0.6;
}

/*---------------*/
/***** Jazz *****/
/*---------------*/

figure.effect-jazz {
  background: -webkit-linear-gradient(-45deg, #f3cf3f 0%,#f33f58 100%);
  background: linear-gradient(-45deg, #f3cf3f 0%,#f33f58 100%);
}

figure.effect-jazz img {
  opacity: 0.9;
}

figure.effect-jazz figcaption::after,
figure.effect-jazz img,
figure.effect-jazz p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-jazz figcaption::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  content: '';
  opacity: 0;
  -webkit-transform: rotate3d(0,0,1,45deg) scale3d(1,0,1);
  transform: rotate3d(0,0,1,45deg) scale3d(1,0,1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

figure.effect-jazz h2,
figure.effect-jazz p {
  opacity: 1;
  -webkit-transform: scale3d(0.8,0.8,1);
  transform: scale3d(0.8,0.8,1);
}

figure.effect-jazz h2 {
  padding-top: 26%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-jazz p {
  padding: 0.5em 2em;
  text-transform: none;
  font-size: 0.85em;
  opacity: 0;
}

figure.effect-jazz:hover img {
  opacity: 0.7;
  -webkit-transform: scale3d(1.05,1.05,1);
  transform: scale3d(1.05,1.05,1);
}

figure.effect-jazz:hover figcaption::after {
  opacity: 1;
  -webkit-transform: rotate3d(0,0,1,45deg) scale3d(1,1,1);
  transform: rotate3d(0,0,1,45deg) scale3d(1,1,1);
}

figure.effect-jazz:hover h2,
figure.effect-jazz:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1,1,1);
  transform: scale3d(1,1,1);
}

/*---------------*/
/***** Ming *****/
/*---------------*/

figure.effect-ming {
  background: #030c17;
}

figure.effect-ming img {
  opacity: 0.9;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-ming figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 2px solid #fff;
  box-shadow: 0 0 0 30px rgba(255,255,255,0.2);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.4,1.4,1);
  transform: scale3d(1.4,1.4,1);
}

figure.effect-ming h2 {
  margin: 20% 0 10px 0;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-ming p {
  padding: 1em;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

figure.effect-ming:hover h2 {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

figure.effect-ming:hover figcaption::before,
figure.effect-ming:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1,1,1);
  transform: scale3d(1,1,1);
}

figure.effect-ming:hover figcaption {
  background-color: rgba(58,52,42,0);
}

figure.effect-ming:hover img {
  opacity: 0.4;
}

/*---------------*/
/***** Lexi *****/
/*---------------*/

figure.effect-lexi {
  background: -webkit-linear-gradient(-45deg, #000 0%,#fff 100%);
  background: linear-gradient(-45deg, #000 0%,#fff 100%);
}

figure.effect-lexi img {
  margin: -10px 0 0 -10px;
  max-width: none;
  width: -webkit-calc(100% + 20px);
  width: calc(100% + 20px);
  opacity: 1;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(10px,10px,0);
  transform: translate3d(10px,10px,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width:100%;
  height:auto;
}

figure.effect-lexi figcaption::before,
figure.effect-lexi p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-lexi figcaption::before {
  position: absolute;
  right: -100px;
  bottom: -100px;
  width: 300px;
  height: 300px;
  border: 2px solid $white;
  border-radius: 50%;
  box-shadow: 0 0 0 900px rgba(255,255,255,0.2);
  content: '';
  opacity: 0;
  -webkit-transform: scale3d(0.5,0.5,1);
  transform: scale3d(0.5,0.5,1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

figure.effect-lexi:hover img {
  opacity: 0.6;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);

}

figure.effect-lexi h2 {
  text-align: left;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(5px,5px,0);
  transform: translate3d(5px,5px,0);
}

figure.effect-lexi p {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 1.5em 1.5em 0;
  width: 160px;
  text-align: right;
  opacity: 0;
  -webkit-transform: translate3d(20px,20px,0);
  transform: translate3d(20px,20px,0);
}

figure.effect-lexi:hover figcaption::before {
  opacity: 1;
  -webkit-transform: scale3d(1,1,1);
  transform: scale3d(1,1,1);
}

figure.effect-lexi:hover h2,
figure.effect-lexi:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/*---------------*/
/***** Duke *****/
/*---------------*/

figure.effect-duke {
  background: -webkit-linear-gradient(-45deg, #34495e 0%,#cc6055 100%);
  background: linear-gradient(-45deg, #34495e 0%,#cc6055 100%);
}

figure.effect-duke img,
figure.effect-duke p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-duke:hover img {
  opacity: 0.1;
  -webkit-transform: scale3d(2,2,1);
  transform: scale3d(2,2,1);
}

figure.effect-duke h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: scale3d(0.8,0.8,1);
  transform: scale3d(0.8,0.8,1);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

figure.effect-duke p {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
  padding: 30px;
  border: 2px solid #fff;
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  -webkit-transform: scale3d(0.8,0.8,1);
  transform: scale3d(0.8,0.8,1);
  -webkit-transform-origin: 50% -100%;
  transform-origin: 50% -100%;
}

figure.effect-duke:hover h2,
figure.effect-duke:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1,1,1);
  transform: scale3d(1,1,1);
}

/* Media queries */
@media screen and (max-width: 50em) {
  .content {
    padding: 0 10px;
    text-align: center;
  }
  .grid figure {
    display: inline-block;
    float: none;
    margin: 0 auto;
    width: 100%;
  }
}





