// Padding Helpers

// Top & Bottom
.padding-default {
    padding:2rem;
}

.padding-default-small {
    padding:0.5rem;
}


.padding-top-tiny-tiny {
   padding-top: rem-calc(7);
}
.padding-top-tiny {
   padding-top: rem-calc(10);
}
.padding-top-small {
   padding-top: rem-calc(15);
}
.padding-top-medium {
   padding-top: rem-calc(20);
}
.padding-top-large {
   padding-top: rem-calc(25);
}
.padding-top-xlarge {
   padding-top: rem-calc(50);
}

// Bottom Only
/*.padding-bottom-default {
    @include padding( 'default', 'bottom' );
}

.padding-bottom-reduced {
    @include padding( 'reduced', 'bottom' );
}

.padding-bottom-increased {
    @include padding( 'increased', 'bottom' );
}*/

.padding-bottom-tiny-tiny {
   padding-bottom: rem-calc(7);
}
.padding-bottom-tiny {
   padding-bottom: rem-calc(10);
}
.padding-bottom-small {
   padding-bottom: rem-calc(15);
}
.padding-bottom-medium {
   padding-bottom: rem-calc(20);
}
.padding-bottom-large {
   padding-bottom: rem-calc(25);
}
.padding-bottom-xlarge {
   padding-bottom: rem-calc(50);
}

// Remove padding
.remove-padding {
    padding: 0;

    &-override { padding: 0 !important; }
    
    @include breakpoint(small only) {
        &-for-small { padding: 0; }
    }
    
    @include breakpoint(medium only) {
        &-for-medium { padding: 0; }
    }
    
    @include breakpoint(large) {
        &-for-large { padding: 0; }
    }

    @include breakpoint($global-width up) {
        &-global-width { padding-top: 0; }
    }
}

// Remove top padding
.remove-padding-top {
    padding-top: 0;

    &-override { padding-top: 0 !important; }
    
    @include breakpoint(small only) {
        &-for-small { padding-top: 0; }
    }
    
    @include breakpoint(medium only) {
        &-for-medium { padding-top: 0; }
    }
    
    @include breakpoint(large) {
        &-for-large { padding-top: 0; }
    }

    @include breakpoint($global-width up) {
        &-global-width { padding-top: 0; }
    }
}

// Remove right padding
.remove-padding-right {
    padding-right: 0;

    &-override { padding-right: 0 !important; }

    @include breakpoint(small only) {
        &-for-small { padding-right: 0; }
    }

    @include breakpoint(medium only) {
        &-for-medium { padding-right: 0; }
    }

    @include breakpoint(large) {
        &-for-large { padding-right: 0; }
    }

    @include breakpoint($global-width up) {
        &-global-width { padding-top: 0; }
    }
}

// Remove bottom padding
.remove-padding-bottom {
    padding-bottom: 0;

    &-override { padding-bottom: 0 !important; }

    @include breakpoint(small only) {
        &-for-small { padding-bottom: 0; }
    }

    @include breakpoint(medium only) {
        &-for-medium { padding-bottom: 0; }
    }

    @include breakpoint(large) {
        &-for-large { padding-bottom: 0; }
    }

    @include breakpoint($global-width up) {
        &-global-width { padding-top: 0; }
    }
}

// Remove left padding
.remove-padding-left {
    padding-left: 0;
    
    &-override { padding-left: 0 !important; }

    @include breakpoint(small only) {
        &-for-small { padding-left: 0; }
        &-for-small-override { padding-left: 0 !important; }
    }

    @include breakpoint(medium) {
        &-for-medium { padding-left: 0; }
        &-for-medium-override { padding-left: 0 !important; }
    }

    @include breakpoint(large) {
        &-for-large { padding-left: 0; }
        &-for-large-override { padding-left: 0 !important; }
    }

    @include breakpoint($global-width up) {
        &-global-width { padding-top: 0; }
    }
}

.padding-fifteen {
    padding-top:15px;
}
