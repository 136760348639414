

.color-white {
	color: $white;
}

.color-gray {
	color: $dark-gray;
}

.color-black {
	color: $black;
}

.color-alternative {
	color: $alternative-color;
}