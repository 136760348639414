.sites-button {
  background: $primary-color;
  font-size: 1.25rem;
  font-weight: bold;
  box-shadow: 0 -2px 0 rgba(0,0,0,0.2) inset;
  border-radius: 3px;
  border: none;
}

.button {
	background-color: $primary-color !important;
}

.large:hover {
	background-color: $black !important;
}

.transprent-button {
	background: transprent;
	border:2px solid $white;
	color:$white;
	width:200px;
	padding:10px;
	display: block;
    text-align: center;

	&:hover {
		border:2px solid $primary-color;
		color: $primary-color;
	}
}